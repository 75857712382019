import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Textarea,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import heic2any from "heic2any";
import Logo from "assets/LOGO.svg";
import Learner from "assets/computer-guy-meme.svg";
import Helper from "assets/this-is-fine.svg";
import { useState, useRef, useEffect } from "react";
import CopyImageButton from "Components/CopyImageButton";
import LoadingSpinner from "Components/Loading/LoadingSpinner";
import { setInvitationToken, setPlatformToken } from "RTK/Slice/userSlice";
import { navigateToMain } from "util/constant";
import TempChatBot from "./TempChatBot";
import photo from "assets/photo.svg";
import Learn from "assets/computer-guy-meme.svg";
import TokenOutModal from "Components/Modal/TokenOutModal";
import ErrorModal from "Components/Modal/ErrorModal";
import queryString from "query-string";
import { message } from "antd";
import PreviewImage from "Components/PreviewImage";
import { handleTokenModal } from "RTK/Slice/modalSlice";

const ChatRoom = () => {
  const { isAuthenticated, currentChatId, breakpoint } = useSelector(
    (state) => state.user
  );
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const [learnerHover, setLearnerHover] = useState(false);
  const [helperHover, setHelperHover] = useState(false);
  const fileInputRef = useRef();
  const [showCopyContainer, setShowCopyContainer] = useState(false); //显示拷贝的图片
  const [textAreaInput, setTextAreatInput] = useState(""); //输入框内容
  const [pastedImage, setPastedImage] = useState(null); //存放拷贝进来的图片
  const [uploadFile, setUploadFile] = useState(null); //存放拷贝进来的图片
  const [openErrorModal, setOpenErrorModal] = useState(false); // 提醒系统崩盘
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const location = useLocation();
  const toast = useToast();
  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    setInProgress(false);
    if (queryParams.token) {
      dispatch(setInvitationToken(queryParams.token));
    }
    if (queryParams.platform) {
      dispatch(setPlatformToken(queryParams.platform));
    }
    //添加粘贴事件监测
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, []);

  useEffect(() => {
    if (!currentChatId) {
      setInProgress(false);
    }
  }, [currentChatId]);
  //上传了图片
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setUploadFile(selectedFile);
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/tiff",
        "image/heic",
      ];
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.type === "image/heic") {
          readHeicImage(selectedFile, (base64String) => {
            setPastedImage(base64String.split(",")[1]);
          });
        } else {
          convertToBase64(selectedFile, (base64String) => {
            setPastedImage(base64String.split(",")[1]);
          });
        }
        //显示图片
        setShowCopyContainer(true);
      } else {
        e.target.value = null;
        message.warning("No support format.");
      }
    }
  };

  // 关闭粘贴的图片显示
  const handleClose = () => {
    setShowCopyContainer(false);
    setPastedImage(null);
    setUploadFile(null);
    setTextAreatInput("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  //图片文件转换成Base64
  const convertToBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => console.log(error);
  };

  //读取HEIC文件
  const readHeicImage = async (file, callback) => {
    if (file) {
      try {
        setLoading(true);
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        setLoading(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          callback(reader.result);
        };
        reader.readAsDataURL(convertedBlob);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  //点击了选择不用的模式
  const handleMode = async (modes) => {
    setMode(modes);
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
    if (uploadFile || textAreaInput) {
      setInProgress(true);
    } else {
      toast({
        description: "Please input an image or text",
        position: "top",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  //点击上传图片
  const handleClick = () => {
    fileInputRef.current.click();
  };

  //粘贴图片
  const handlePaste = (e) => {
    const getImageFromClipboard = (
      e.clipboardData || e.originalEvent.clipboardData
    ).items;
    for (let image in getImageFromClipboard) {
      const img = getImageFromClipboard[image];
      if (img?.kind === "file" && img?.type.startsWith("image/")) {
        // console.log("img", img);
        const blob = img.getAsFile();
        setUploadFile(blob);
        convertToBase64(blob, (base64String) => {
          setPastedImage(base64String.split(",")[1]);
          //拷贝图片
          setShowCopyContainer(true);
        });
        e.preventDefault();
        break;
      }
    }
  };

  const handleTokenOut = (bool) => {
    dispatch(handleTokenModal(bool));
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {inProgress ? (
            <TempChatBot
              prompt={textAreaInput}
              image_file={uploadFile}
              pastedImage={pastedImage}
              mode={mode}
              language={language}
              setInProgress={setInProgress}
              isMobile={breakpoint === "mobile"}
              setOpenTokenModal={handleTokenOut}
              setOpenErrorModal={setOpenErrorModal}
            />
          ) : (
            <>
              <Flex
                direction="column"
                bg="#feeede"
                height={breakpoint === "mobile" ? "80%" : "100%"}
                width={{
                  base: "100%",
                  xl: "100%",
                }}
                borderRadius="20px"
                border={{
                  base: "1px solid black",
                  xl: "unset",
                }}
                m={{
                  base: "20px",
                  xl: "0px",
                }}
              >
                <Flex
                  justify="center"
                  height="20%"
                  align="top"
                  alignItems={"flex-start"}
                  hideBelow={"xl"}
                >
                  <Image
                    src={Logo}
                    width="40%"
                    mt="50px"
                    onClick={navigateToMain}
                    cursor="pointer"
                  />
                </Flex>

                <Flex
                  justify="center"
                  align="center"
                  height="20%"
                  minHeight="70px"
                  hideBelow={"xl"}
                >
                  <Box
                    width="70%"
                    height="70px"
                    lineHeight="70px"
                    onClick={handleClick}
                  >
                    <Button
                      bg="#D0F979"
                      width="100%"
                      height="100%"
                      borderRadius="10px"
                      fontSize="30px"
                      display="inline-block"
                    >
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                      <span>
                        <strong>{t("UploadMessage")}</strong>
                      </span>
                    </Button>
                  </Box>
                </Flex>

                {/*mobile upload button*/}
                {!showCopyContainer && (
                  <Button
                    display="flex"
                    backgroundColor="#D0F979"
                    borderRadius="10px"
                    marginTop="10%"
                    width="80%"
                    padding="20px"
                    height="40px" // 改为适当的高度
                    border="1px solid black"
                    alignItems="center"
                    onClick={handleClick}
                    mx={"auto"}
                    hideFrom={"xl"}
                  >
                    <Box
                      flex="1"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap="10px"
                    >
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        display="none"
                        accept="image/*"
                      />
                      {photo && (
                        <Image
                          src={photo}
                          alt="photo"
                          maxHeight="100%"
                          maxWidth="100%"
                        />
                      )}
                      <Text fontSize="16px">{t("MobileUpload")}</Text>
                    </Box>
                  </Button>
                )}

                <Flex
                  justify="center"
                  height={{ base: showCopyContainer ? "auto" : 0, xl: "20%" }}
                  minHeight={{ base: 0, xl: "70px" }}
                >
                  <Box width={{ base: "80%", xl: "70%" }}>
                    {showCopyContainer ? (
                      <Flex
                        bg="#edffc7"
                        border={{ base: "1px solid black", xl: "unset" }}
                        borderRadius={{ base: "10px", xl: "unset" }}
                        mt={{ base: "10%", xl: "unset" }}
                        position="relative"
                        p={{ base: 0, xl: "10px" }}
                        flexDirection={{ base: "column", xl: "row" }}
                      >
                        <Box
                          width={{ base: "100%", xl: "50%" }}
                          height="200px"
                          paddingLeft={{ base: 0, xl: "10px" }}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <PreviewImage
                            src={`data:image/jpeg;base64,${pastedImage}`}
                            alt="Pasted Image"
                            maxWidth="90%"
                            maxHeight="100%"
                            objectFit="contain"
                          />
                        </Box>
                        <Box
                          width={{ base: "100%", xl: "50%" }}
                          height="200px"
                          padding="10px"
                          mr={"10px"}
                        >
                          <Textarea
                            resize="none"
                            height="100%"
                            padding="10px"
                            bg="#fdf6ad"
                            placeholder="Copy your question in text here..."
                            onChange={(e) => {
                              const value = e.target.value.trim();
                              if (!value && e.target.value === "\n") {
                                return;
                              }
                              setTextAreatInput(e.target.value);
                            }}
                            value={textAreaInput}
                            style={{
                              fontSize: "14px",
                              color: "black",
                            }}
                          />
                        </Box>
                        <Box
                          width="20px"
                          height="20px"
                          borderRadius="50%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border="3px solid black"
                          marginRight="5px"
                          marginTop="5px"
                          onClick={handleClose}
                          cursor="pointer"
                          position="absolute"
                          right="2px"
                          top="2px"
                        >
                          <Box fontWeight="bold">X</Box>
                        </Box>
                      </Flex>
                    ) : (
                      <CopyImageButton />
                    )}
                  </Box>
                </Flex>

                {!showCopyContainer && (
                  <Flex justify="center">
                    <Box
                      width={{ base: "80%", xl: "70%" }}
                      position="relative"
                      my={{ base: "10%", xl: 0 }}
                    >
                      <Textarea
                        resize="none"
                        height="100px"
                        maxHeight="200px"
                        padding="10px"
                        bg="#fdf6ad"
                        placeholder="Copy your question in text here..."
                        onChange={(e) => {
                          const value = e.target.value.trim();
                          if (!value && e.target.value === "\n") {
                            return;
                          }
                          setTextAreatInput(e.target.value);
                        }}
                        value={textAreaInput}
                        style={{
                          fontSize: "14px",
                        }}
                        color={"black"}
                      />
                    </Box>
                  </Flex>
                )}

                {/*pc button*/}
                <Flex justify="center" my="auto" hideBelow={"xl"}>
                  <Box width="70%">
                    <Flex justify="space-between">
                      <Button
                        width="45%"
                        height="50px"
                        borderRadius="10px"
                        fontSize="26px"
                        fontWeight="600"
                        borderColor="black"
                        color="black"
                        bg={helperHover ? "#D0F979" : "#d2cffc"}
                        opacity={textAreaInput || pastedImage ? "1" : "0.5"}
                        onClick={() => handleMode("helper")}
                        onMouseEnter={() => setHelperHover(true)}
                        onMouseLeave={() => setHelperHover(false)}
                      >
                        <Flex justify="center" align="center">
                          {helperHover && (
                            <Image
                              src={Helper}
                              alt="Helper mode"
                              height="45px"
                              marginRight="10px"
                            />
                          )}
                          {t("HelperMode")}
                        </Flex>
                      </Button>
                      <Button
                        width="45%"
                        height="50px"
                        borderRadius="10px"
                        fontSize="26px"
                        fontWeight="600"
                        borderColor="black"
                        color="black"
                        bg={learnerHover ? "#D0F979" : "#d2cffc"}
                        opacity={textAreaInput || pastedImage ? "1" : "0.5"}
                        onClick={() => handleMode("learner")}
                        onMouseEnter={() => setLearnerHover(true)}
                        onMouseLeave={() => setLearnerHover(false)}
                      >
                        <Flex justify="center" align="center">
                          {learnerHover && (
                            <Image
                              src={Learner}
                              alt="Learner mode"
                              height="45px"
                              marginRight="10px"
                            />
                          )}
                          {t("LearnerMode")}
                        </Flex>
                      </Button>
                    </Flex>
                  </Box>
                </Flex>

                {/*  mobile button*/}
                <Flex direction={"column"} align={"center"} hideFrom={"xl"}>
                  <Box
                    width="80%"
                    marginTop="20px"
                    padding={pastedImage ? "0 20px" : "0"}
                    border="1px solid black"
                    borderRadius="10px"
                    backgroundColor="#D0F979"
                    opacity={textAreaInput || pastedImage ? 1 : 0.6}
                    onClick={() => handleMode("learner")}
                  >
                    <Button
                      width="100%"
                      height="100%"
                      minHeight="20px"
                      backgroundColor="#D0F979"
                      border="none"
                      display="flex"
                      borderRadius={pastedImage ? "0" : "10px"}
                      gap="10px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        src={Learn}
                        alt="learner"
                        height="100%"
                        width="40px"
                      />
                      <Text fontSize="16px" fontWeight="500" lineHeight="24px">
                        {t("LearnerMode")}
                      </Text>
                    </Button>
                  </Box>

                  <Box
                    width="80%"
                    margin="5% 0"
                    padding={pastedImage ? "0 20px" : "0"}
                    border="1px solid black"
                    borderRadius="10px"
                    backgroundColor="#D0F979"
                    opacity={textAreaInput || pastedImage ? 1 : 0.6}
                    onClick={() => handleMode("helper")}
                  >
                    <Button
                      width="100%"
                      height="100%"
                      minHeight="20px"
                      backgroundColor="#D0F979"
                      border="none"
                      display="flex"
                      borderRadius={pastedImage ? "0" : "10px"}
                      gap="10px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        src={Helper}
                        alt="helper"
                        height="100%"
                        width="40px"
                      />
                      <Text fontSize="16px" fontWeight="500" lineHeight="24px">
                        {t("HelperMode")}
                      </Text>
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </>
          )}
        </>
      )}
      <TokenOutModal shouldClose={true} />
      <ErrorModal open={openErrorModal} setOpen={setOpenErrorModal} />
    </>
  );
};

export default ChatRoom;
