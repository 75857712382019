import { createSlice } from "@reduxjs/toolkit";

// Initial state template
const initialState = {
  chat_id: "",
  mode: "",
  prompt: "",
  currentLanguage: "",
  image_file: null,
  status: false,
};

export const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setFile: (state, action) => {
      state.chat_id = action.payload.chat_id;
      state.mode = action.payload.mode;
      state.prompt = action.payload.prompt;
      state.language = action.payload.language;
      state.image_file = action.payload.image_file;
    },
    clearFile: (state) => {
      const copyState = {
        ...state,
        chat_id: "",
        prompt: "",
        mode: "",
        currentLanguage: "",
        image_file: null,
      };
      state = copyState;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setFile, clearFile, setStatus } = fileSlice.actions;
export default fileSlice.reducer;
