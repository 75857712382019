import { useState, useEffect } from "react";
import PasswordModal from "./PasswordModal";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import PasswordIcon from "assets/Password.svg";
import SuccessKid from "assets/success-kid.svg";
import { useTranslation } from "react-i18next";
import { updatePassword } from "util/userRequest";
import { loginEventTrigger, loginUser, updateToken } from "RTK/Slice/userSlice";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [displayMessage, setDisplayMessage] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.hash) {
      const searchParams = new URLSearchParams(location.hash);
      const accessToken = searchParams.get("#access_token") || "";
      const refreshToken = searchParams.get("refresh_token") || "";
      const expired_at = searchParams.get("expiured_at") || "";
      const expired_in = searchParams.get("expired_in");
      const token_type = searchParams.get("token_type");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      dispatch(
        updateToken({
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_in: expired_in,
          expires_at: expired_at,
          token_type,
        })
      );
      dispatch(
        loginUser({
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_in: expired_in,
          expires_at: expired_at,
          token_type,
        })
      );
      dispatch(loginEventTrigger(true));
    }
  }, []);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleConfirm();
    }
  };

  const handleConfirm = async () => {
    if (!password || !confirm) {
      setDisplayMessage("Password and Confirm password is required");
      return;
    }

    try {
      if (displayMessage === "") {
        const { error } = await updatePassword({
          password: password,
        });
        if (error) {
          setDisplayMessage((error as Error).message);
        } else {
          handleCancel();
          dispatch(loginEventTrigger(true));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = () => {
    setConfirm("");
    setPassword("");
    navigate("/");
  };

  const handleBlur = () => {
    if (password === confirm) {
      setDisplayMessage("");
    } else {
      setDisplayMessage(t("PasswordDoesNotMatch"));
    }
  };

  return (
    <Box h={"100%"} w={"100%"}>
      <PasswordModal>
        <VStack spacing={4} align="stretch" width={{ base: "90%", xl: "70%" }}>
          <Box>
            <Text>{t("ResetPassword")}</Text>
          </Box>
          <Box>
            <Text fontSize={{ xl: "18px" }} fontWeight={500}>
              {t("ResetTypePassword")}
            </Text>
          </Box>

          <Box textAlign="left" color="red.500">
            <Text>{displayMessage}</Text>
          </Box>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Image src={PasswordIcon} />
            </InputLeftElement>
            <Input
              h={"50px"}
              border={"1px solid black"}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleEnter}
              placeholder="Password"
              background={"#d0f979"}
              required={true}
            />
          </InputGroup>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Image src={PasswordIcon} />
            </InputLeftElement>
            <Input
              h={"50px"}
              border={"1px solid black"}
              type="password"
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              onKeyDown={handleEnter}
              placeholder="Confirm Password"
              background={"#d0f979"}
              required={true}
              onBlur={handleBlur}
              _focus={{ outline: "none" }}
            />
          </InputGroup>

          <Flex justifyContent="center" p={5}>
            <Image src={SuccessKid} />
          </Flex>

          <Flex justifyContent="space-between" mt={4} flexDirection={"column"}>
            <Button
              onClick={handleConfirm}
              h={"60px"}
              backgroundColor={"#d2cffc"}
              border={"1px solid #000"}
            >
              {t("Confirm")}
            </Button>
            <Button
              onClick={handleCancel}
              h={"60px"}
              mt={"10px"}
              border={"1px solid #000"}
              bg={"transparent"}
            >
              {t("Cancel")}
            </Button>
          </Flex>
        </VStack>
      </PasswordModal>
    </Box>
  );
};

export default ResetPassword;
