import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Image, useDisclosure, Text } from "@chakra-ui/react";
import banner1 from '../assets/banner1.svg';  // Adjust path if needed
import banner2 from '../assets/banner2.svg';
import { SubscribeModel } from "./Modal"; // Import the modal component
import { setIsClickSubscribe, resetIsClickSubscribe } from 'RTK/Slice/modalSlice';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface BannerProps {
  isAuthenticated: boolean;
  isPremiumUser: boolean;
}

const Banner: React.FC<BannerProps> = ({ isAuthenticated, isPremiumUser }) => {
  const [bannerVisible, setBannerVisible] = React.useState(!isPremiumUser);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isClickSubscribe} = useSelector((state: any) => state.modal);
  const { t } = useTranslation();

  // Update banner visibility when user status changes
  useEffect(() => {
    setBannerVisible(!isPremiumUser);
  }, [isPremiumUser]);

  // Open modal when authenticated and subscription click state is true
  useEffect(() => {
    if (isAuthenticated && isClickSubscribe) {
      onOpen();
      dispatch(resetIsClickSubscribe()); // Reset state after opening modal
    }
  }, [isAuthenticated, isClickSubscribe, onOpen, dispatch]);



  const handleClick = () => {
    dispatch(setIsClickSubscribe());
    localStorage.setItem('isClickSubscribe',"true") // Dispatch action to set isClickSubscribe
    if (isAuthenticated) {
      onOpen();
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {bannerVisible && (
        <Box 
          w="100%" 
          padding={{ base: "5px", xl: "10px" }}
          textAlign="center"
        >
          <Flex
              direction="row"
              display="flex"
              justify="center"
              align="center"
              width="100%"
              height="100%" 
            >
              <Box textAlign="center">
                <Text
                  display="inline"
                  fontFamily="'Kumbh Sans', sans-serif"
                  fontWeight="700"
                  color="#D0F979"
                  textShadow="1px 1px 0px #000000, -1px -1px 0px #000000, 1px -1px 0px #000000, -1px 1px 0px #000000"
                  onClick={handleClick}
                  cursor="pointer"
                  textDecoration="underline"
                  fontSize="clamp(6px, 5vw, 24px)"  // Increases the minimum and maximum font size
                  lineHeight="100%" 
                  flexGrow={1}
                 
                >
                  {t("Subscribe")}
                </Text>
                <Text
                  display="inline" 
                  marginLeft="5px"
                  fontFamily="'Kumbh Sans', sans-serif"
                  fontWeight="700"
                  color="#000000"
                  fontSize="clamp(6px, 5vw, 24px)"  // Increases the minimum and maximum font size
                  lineHeight="100%" 
                  flexGrow={1} 
                 
                >
                  {t("to get Unlimited Coins ! Up to 50% off for PREMIUM PLAN")}
                </Text>
              </Box>
            </Flex>
        </Box>
      )}
      <SubscribeModel isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Banner;


