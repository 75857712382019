import axios from "axios";
import { request } from "./request";

//获取用户credit
export async function getUserCredit() {
  const result = await request("get", `credit`);
  return result;
}

export async function signout() {
  const result = await request("delete", "logout");
  return result;
}

export async function getChatHistory() {
  const result = await request("get", `all_chats`);
  return result;
}

export async function getUserInfo() {
  const { data } = await request("get", "user_info");
  return data;
}

export async function getExample() {
  const result = await request("get", "examples");
  return result;
}

export async function postQuestion(formData) {
  const result = await request("post", "question", formData);
  return result;
}

export const getToken = async (userId) => {
  const result = await request("get", `invitation/${userId}`);
  return result?.data?.token;
};

export const getReferrerId = async (token) => {
  const result = await request("get", `referrer/${token}`);
  return {
    isInvited: result?.data?.is_invited,
    referrerId: result?.data?.referrer_id,
  };
};

export const getChat = async (chat_id) => {
  const result = await request("get", `chat/${chat_id}`);
  return result;
};

export const putCredit = async (id) => {
  const result = await request("put", "credit", {
    user_id: id,
  });
};

export const uploadPhoto = async (param) => {
  const result = await request("post", "question_photo", param);
  return result.status === 200;
};

export const deleteChat = async (id) => {
  const result = await request("delete", `chat/${id}`);
  return result;
};

export const verifyUser = async (invitation_token, user_id) => {
  const result = await request("post", `invitation/verify_user`, {
    user_id: user_id,
    invitation_token: invitation_token,
  });
  return result;
};

export const getDailyBonus = async (user_id) => {
  const result = await request("get", `daily_bonus/${user_id}`);
  return result;
};

export const getSharedId = async (param) => {
  const { data } = await request("post", `shared_chat/create`, param);
  return data.shared_chat_id;
};

export const getSharedChat = async (Id: string) => {
  const result = await request("get", `shared_chat/${Id}`);
  return result;
};

export interface IRefereeItem {
  guest_email: string;
  join_date: string;
  bonus: number;
  referrer_id: string;
  isNotify: boolean;
}
export const deleteUser = async (email) => {
  let result = 200;
  await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/admin/delete_user_by_email`, {
      data: { email: email },
    })
    .then((response) => {
      result = 200;
    })
    .catch((err) => {
      if (err.response.status === 409) {
        result = 409;
      } else {
        result = 500;
      }
    });
  return result;
};
export const getInvitationList = async (id): Promise<IRefereeItem[]> => {
  const result = await request("get", `invitation/list/${id}`);
  return result.data;
};

export const getInvitationIsNotified = async (id) => {
  const result = await request("get", `invitation/is_notified/${id}`);
  return result;
};

export const updateInvitationIsNotified = async (user_id, guest_email) => {
  console.log(user_id);
  const result = await request("put", `invitation/is_notified`, {
    user_id: user_id,
    guest_email: guest_email,
  });
  return result;
};

export const updateUserProfile = async (user_id, platform_id) => {
  const result = await request("post", `url_platforms/update_user_profile`, {
    user_id: user_id,
    platform_id: platform_id,
  });
};

export async function getSSE(formData) {
  const result = await request("post", "chat/new_chat", formData);
  return result;
}

// for stripe customer: service to fetch customer information
export const getCustomerInfo = async () => {
  try {
    const result = await request("get", `user_subscription/info`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const getSubscriptionInfo = async () => {
  const { data } = await request("get", `user_subscription`);
  return data;
};

export async function resetPassword(formData) {
  const result = await request("post", "reset_password", formData);
  return result;
}

export async function updatePassword(formData) {
  const result = await request("post", "update_password", formData);
  return result;
}

export async function adminInvite(param) {
  let result = 200;
  await request("post", "invite_user_by_email", param)
    .then((response) => {
      result = 200;
    })
    .catch((err) => {
      if (err.response.status === 409) {
        result = 409;
      } else {
        result = 500;
      }
    });
  return result;
}

export async function getSessionId(formData) {
  const result = await request("post", "create_checkout_session", formData);
  return result;
}
