import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { FC,useState } from "react";
import Coin from "assets/coin.svg";
import Drake from "assets/Graphic_Drake.svg";

interface BonusModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string;
}
const BonusModal: FC<BonusModalProps> = ({ open, setOpen, message }) => {
  const { t } = useTranslation();
  const [displayMessage, setDisplayMessage] = useState(false);
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={() => {
        setOpen(false);
        setDisplayMessage(false);
      }}
      width={"600px"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            marginBottom: "20px",
          }}
        >
          <img src={Coin} alt={"coin"} />
          <span
            style={{ marginLeft: "5px", fontSize: "large", fontWeight: "600" }}
          >
            {t("Congratulation")}
          </span>
        </div>
      </div>
        <div style={{ width: "100%" }}>
          <img src={Drake} alt={"drake"} style={{ width: "100%" }} />
        </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            src={Coin}
            alt={"coin"}
            style={{ width: "51px", height: "51px", margin: "10px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
            <span style={{ fontSize: "large", fontWeight: "600" }}>

            </span>
          <span style={{ fontSize: "large", fontWeight: "600" }}>
            {/* You and Your Friends get bonus! */}
            {message}
            </span>
        </div>

      </div>
      <div
        style={{
          display: "flex",
          flex: "1",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid black",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#D2CFFC",
          fontSize: "large",
          fontWeight: "600",
          marginTop: "20px",
          marginLeft: "0",
          cursor: "pointer",
        }}
        onClick={
            () => {
                setOpen(false);
                setDisplayMessage(false);
              }
        }
      >
        <span>{t("StartUsing")}</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "20px",
          fontSize: "large",
          fontWeight: "600",
          visibility: `${displayMessage ? "visible" : "hidden"}`,
        }}
      >
        {t("ConfirmCopy")}
        <img
          src={Coin}
          style={{
            marginLeft: "10px",
          }}
          alt={"coin"}
        />
      </div>
    </Modal>
  );
};

export default BonusModal;
