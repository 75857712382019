import { useEffect, useState, useRef } from "react";
import { abortFetchTextStream, fetchTextStream } from "util/request";
import { Box, Flex, Input, Image, useToast } from "@chakra-ui/react";
import ChatItem from "Components/ChatItem/ChatItem";
import Helper from "assets/this-is-fine.svg";
import Learner from "assets/computer-guy-meme.svg";
import Send from "assets/purple-send.svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useChatHistory } from "hooks/useChatHistory";
import { getChat, getUserCredit } from "util/userRequest";
import Example_Dialogue from "assets/Examples-Dialogue.json";
import LoadingSpinner from "Components/Loading/LoadingSpinner";
import { setCredit } from "RTK/Slice/userSlice";
import TokenOutModal from "Components/Modal/TokenOutModal";
import ErrorModal from "Components/Modal/ErrorModal";
import { handleTokenModal } from "RTK/Slice/modalSlice";
import "./chatbot.css";

const ChatBot = () => {
  const { user, isAuthenticated, currentMode, currentChatId, breakpoint } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { getQuestions } = useChatHistory();
  const { chatId } = useParams();
  const [chatBotMessages, setChatBotMessages] = useState([]);
  const [keepChat, setKeepChat] = useState(true);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false); //页面加载
  const [loading, setLoading] = useState(true); //页面加载
  const [ccId, setCCID] = useState(chatId); //页面加载
  const scrollRef = useRef(null);
  const prevChatIdRef = useRef(null);
  const firstQuestionRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language, status } = useSelector((state) => state.file);
  const toast = useToast();
  const [openErrorModal, setOpenErrorModal] = useState(false); // 提醒系统崩盘

  function isSafari() {
    const userAgent = navigator.userAgent;

    if (/Safari/.test(userAgent) && /Apple Computer/.test(navigator.vendor)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    abortFetchTextStream();
    setIsTyping(false);
    setCCID(chatId);
  }, [chatId]);

  const refreshCredit = async () => {
    if (user?.user_info?.user_id) {
      const result = await getUserCredit();
      dispatch(setCredit(result.data));
    }
  };

  useEffect(() => {
    if (prevChatIdRef.current) {
      firstQuestionRef.current = null;
    }
    if (ccId) {
      getInitData();
    } else {
      setChatBotMessages([]);
    }
    prevChatIdRef.current = ccId;
  }, [ccId, status]);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatBotMessages]);

  //初始化历史数据
  const getInitData = async () => {
    setLoading(true);
    await getChatHistory();
    setLoading(false);
  };

  //获取历史记录
  const getChatHistory = async () => {
    let historyList = [];
    try {
      setLoading(true);
      let data;
      if (!isAuthenticated) {
        data = Example_Dialogue[currentChatId];
      } else {
        const response = await getChat(currentChatId);
        setKeepChat(response.data?.chat_again);
        data = response.data;
      }
      setLoading(false);

      historyList = [{ content: data["text_prompt"], role: "user" }];

      if (data["image_str"]) {
        historyList[0].imgSrc = data["image_str"];
      }

      if (data["payload"]["messages"].length < 2) {
        firstQuestionRef.current = data["question"];
      } else {
        const messages = data["payload"]["messages"].slice(2);
        messages.forEach((item) => {
          if (item.role === "assistant" || item.role === "user") {
            historyList.push({
              content: item.content,
              role: item.role === "assistant" ? "AI" : "user",
            });
          }
        });
      }
    } catch (e) {
      console.log(
        "[ChatBot.js] getChatHistory error, fail to fetch chat, e",
        e
      );
    }
    //保存数据
    setChatBotMessages([...historyList]);
  };

  // 对话请求
  const handleSubmit = async (text) => {
    if (isTyping) {
      toast({
        description: "AI is answering...",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    //更新question记录
    firstQuestionRef.current = text;
    prepareAIResponse();
    await fetchTextStream(
      "chat",
      handleAIResponse,
      setKeepChat,
      handDoneCallBack,
      setOpenTokenModal,
      setOpenErrorModal,
      { chat_id: ccId, query: text, language: language },
      { method: "POST" }
    );
  };

  const setOpenTokenModal = (bool) => {
    dispatch(handleTokenModal(bool));
  };

  //预处理等待AI回复
  const prepareAIResponse = () => {
    if (isTyping) return;
    setIsTyping(true);
    setChatBotMessages((prevMessages) => [
      ...prevMessages,
      { content: "", role: "AI" },
    ]);
  };

  //接收AI返回的结果
  const handleAIResponse = (response) => {
    // console.log("response", response);
    //由于返回的文本都在一个chunk里面所以这个出来暂时没有问题
    // { content: normalizeString(preprocessLaTeX(response)), role: "AI" },
    setChatBotMessages((prevMessages) => {
      // 获取数组最后一个元素
      const lastMessage = prevMessages[prevMessages.length - 1];
      // 检查最后一个元素是否存在且角色为 'AI'
      if (lastMessage && lastMessage.role === "AI") {
        // 更新最后一个元素的内容
        const updatedLastMessage = {
          ...lastMessage,
          content: lastMessage.content + response,
        };
        // 返回新的消息数组
        return [
          ...prevMessages.slice(0, prevMessages.length - 1),
          updatedLastMessage,
        ];
      } else {
        // 否则，添加一个新的 AI 消息元素
        return [...prevMessages, { content: response, role: "AI" }];
      }
    });
  };

  //文本流接收完的回调
  const handDoneCallBack = async (state) => {
    //结果已经完全返回
    // console.log("handDoneCallBack", state, !state);
    setIsTyping(!state);
    refreshCredit();
    getQuestions();
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter" && !e.shiftKey && input.trim()) {
      //先登录
      if (!isAuthenticated) {
        navigate(`/login`, { replace: true });
        return;
      }
      //等待AI回复
      if (isTyping) {
        toast({
          description: "Waiting AI...",
          status: "warning",
          duration: 2000,
        });
        return;
      }
      //保存信息
      setChatBotMessages([
        ...chatBotMessages,
        { content: input, role: "user" },
      ]);
      //chat操作
      handleSubmit(input);
      setInput("");
    }
  };

  // 获取用户名首字母
  const getUsernameLetter = () => {
    const username = user?.email || "U";
    return username.charAt(0).toUpperCase();
  };

  return (
    <Box
      bg="#feeede"
      height="100%"
      width="100%"
      borderRadius={{ base: "0", xl: "20px" }}
      overflow="auto"
      position="relative"
    >
      {loading
        ? ccId && (
            <Box>
              <LoadingSpinner />
            </Box>
          )
        : ccId && (
            <>
              <Box
                bg="#feeede"
                height="100%"
                width="100%"
                borderRadius="20px"
                overflow="auto"
                ref={scrollRef}
                position="relative"
              >
                <Flex
                  direction="column"
                  width="100%"
                  minHeight="100%"
                  margin="0 auto"
                >
                  <Box
                    flex="1 1 0"
                    position="relative"
                    ref={scrollRef}
                    overflow={{ base: "scroll", xl: "unset" }}
                  >
                    {chatBotMessages.map((message, index) => (
                      <ChatItem
                        key={index}
                        role={message.role}
                        message={message.content}
                        imgSrc={message.imgSrc}
                        username={getUsernameLetter()}
                        scrollRef={scrollRef}
                        typingSpeed={firstQuestionRef.current}
                        lastMessage={index === chatBotMessages.length - 1}
                        isAuthenticated={isAuthenticated}
                      />
                    ))}
                  </Box>

                  <Flex
                    w={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    left="0"
                    right="0"
                    zIndex="999"
                    paddingTop="10px"
                    className={`${
                      isSafari() && breakpoint === "mobile" ? "safari" : ""
                    } input-container`}
                  >
                    <Flex
                      flex="1"
                      border="1px solid black"
                      borderRadius="10px"
                      alignItems="center"
                      justifyContent="center"
                      bg="#D0F979"
                    >
                      <Image
                        src={currentMode !== "learner" ? Learner : Helper}
                        height="40px"
                        width="100%"
                        minWidth={{ base: "40px", xl: "80px" }}
                      />
                    </Flex>
                    <Input
                      width={{ base: "90%", xl: "85%" }}
                      paddingLeft="15px"
                      margin="0 10px"
                      borderRadius="0"
                      padding="10px"
                      placeholder={
                        keepChat ? t("StartTyping") : t("ChatOutOfLimitation")
                      }
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        if (!value && e.target.value === "\n") {
                          return;
                        }
                        setInput(e.target.value);
                      }}
                      _placeholder={{ color: "#86909c", fontSize: "14px" }}
                      bg={"white"}
                      value={input}
                      onKeyDown={handleEnter}
                      disabled={!isAuthenticated || !keepChat}
                    />
                    <Flex
                      onClick={() => {
                        if (input.trim() !== "") {
                          setChatBotMessages([
                            ...chatBotMessages,
                            { content: input, role: "user" },
                          ]);
                          handleSubmit(input);
                          setInput("");
                        }
                      }}
                      borderRadius="10px"
                      border="1px solid black"
                      minW={{ base: "40px", xl: "auto" }}
                      overflow={"hidden"}
                    >
                      <Image
                        src={Send}
                        height="40px"
                        bg="#d2cffc"
                        cursor="pointer"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </>
          )}
      <TokenOutModal />
      <ErrorModal open={openErrorModal} setOpen={setOpenErrorModal} />
    </Box>
  );
};

export default ChatBot;
