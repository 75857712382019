import { Box } from "@chakra-ui/react";
import Logo from "../../assets/LOGO.svg";
import ThisIsFine from "../../assets/this-is-fine.svg";
import ComputerGuy from "../../assets/computer-guy-meme.svg";
import Flower from "../../assets/Flower.svg";
import Diamond from "../../assets/yellow-credit.svg";
import Coin from "../../assets/coin.svg";
import Yaoming from "../../assets/yaoming.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ShareNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "80%",
        marginTop: "15%",
        marginLeft: "10px",
        justifyContent: "space-around",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <Box>
        <img src={Logo} style={{ width: "100%", height: "100%" }} />
      </Box>
      <Box
        sx={{
          backgroundColor: "#DEDCFF",
          border: "1px solid black",
          width: "100%",
          borderRadius: "10px",
          padding: "30px",
          height: "100%",
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "12px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "#d0f979",
            borderRadius: "10px",
            border: "2px solid transparent",
            backgroundClip: "content-box",
          },
        }}
      >
        <span
          style={{
            fontWeight: "700",
            fontSize: "large",
            lineHeight: "20px",
            display: "block",
          }}
        >
          {t("ShareH1")}
        </span>
        <span
          style={{
            fontWeight: "500",
            fontSize: "small",
            lineHeight: "14px",
            display: "block",
            marginTop: "20px",
          }}
        >
          {t("ShareSubH1")}
        </span>

        <Box
          style={{
            width: "100%",
            marginTop: "10%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* learn mode========================================================*/}
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              borderRadius: "10px",
              width: "100%",
              height: "40px",
              backgroundColor: "#D0F979",
              border: "1px solid black",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <img
              src={ThisIsFine}
              alt={"helper-button"}
              style={{ height: "100%", margin: "0 20px" }}
            />
            <span
              style={{
                fontWeight: "700",
                fontSize: "large",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {t("LearnerMode")}
            </span>
          </Box>

          <Box
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <img
              src={Flower}
              alt={"helper-button-flower"}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            />
            <span style={{ textAlign: "start" }}>{t("ShareSubH2")}</span>
          </Box>

          {/* helper mode========================================================*/}
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              borderRadius: "10px",
              width: "100%",
              height: "40px",
              backgroundColor: "#D0F979",
              border: "1px solid black",
              cursor: "pointer",
              marginTop: "10%",
            }}
            onClick={handleClick}
          >
            <img
              src={ComputerGuy}
              alt={"helper-button"}
              style={{ height: "100%", margin: "0 20px" }}
            />
            <span
              style={{
                fontWeight: "700",
                fontSize: "large",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              {t("HelperMode")}
            </span>
          </Box>

          <Box
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "flex-start",
              gap: "5px",
            }}
          >
            <img
              src={Flower}
              alt={"helper-button-flower"}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            />
            <span style={{ textAlign: "start" }}>{t("ShareSubH3")}</span>
          </Box>

          {/* Use for free ======================================================*/}
          <Box
            style={{
              marginTop: "10%",
              display: "flex",
              gap: "10px",
              alignItems: "flex-start",
            }}
          >
            <img src={Diamond} style={{ marginTop: "5px" }} />
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "700" }}>{t("ShareSubH4")}</span>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "14px",
                }}
              >
                {t("BoldShareSubH4")}
              </span>
            </Box>
          </Box>

          {/* Invite friends =====================================================*/}
          <Box
            style={{
              marginTop: "3%",
              display: "flex",
              gap: "10px",
              alignItems: "flex-start",
            }}
          >
            <img src={Coin} style={{ marginTop: "5px" }} />
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "700" }}>{t("ShareSubH5")}</span>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "14px",
                }}
              >
                {t("BoldShareSubH5")}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          display: "flex",
          width: "100%",
          border: "1px solid black",
          borderRadius: "10px",
          height: "54px",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "#DEDCFF",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <img
          src={Yaoming}
          style={{
            height: "90%",
            width: '20%',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <span
          style={{
            fontWeight: "700",
            fontSize: "large",
            flex: "2",
            display: "flex",
          }}
        >
          {t("ShareH2")}
        </span>
      </Box>
    </Box>
  );
};

export default ShareNav;
