import { Center, Flex, List, ListItem, Text } from "@chakra-ui/react";
import { FC } from "react";
import { t } from "i18next";



export const BasicPlan: FC = ( ) => {
  return (
    <>
      <Flex
        hideBelow={'md'}
        border={"1px solid black"} borderRadius={"8px"} bg={"#FDF6AD"} px={8} py={6}
        flexDirection={"column"} flex={1} h={"fit-content"}>
        <Center flexDirection={"column"}>
          <Text fontWeight={700} fontSize={"16px"}>{t("subscribe.basic.plan")}</Text>
          <Text w={"160px"} bg={"#E5F7A5"} textAlign={"center"} mt={4} fontSize={"12px"} h={6} lineHeight={6}
                borderRadius={"16px"}>Free</Text>
        </Center>
        <Text fontSize="12px" fontWeight="bold" mb={1} mt={3}>
          {t("subscribe.usage")}
        </Text>
        <List spacing={1} styleType="disc" pl={5} fontSize="12px">
          <ListItem>{t("subscribe.basic.usage1")}</ListItem>
          <ListItem>{t("subscribe.basic.usage2")}</ListItem>
          <ListItem>{t("subscribe.basic.usage3")}</ListItem>
        </List>

        <Text fontSize="12px" fontWeight="bold" my={1}>
          {t("subscribe.support")}
        </Text>
        <List spacing={1} styleType="disc" pl={5} fontSize="12px">
          <ListItem>{t("subscribe.support.dec")}</ListItem>
        </List>

        <Text fontSize="12px" fontWeight="bold" my={1}>
          {t("subscribe.ads")}
        </Text>
        <List spacing={1} styleType="disc" pl={5} fontSize="12px">
          <ListItem>{t("subscribe.ads.desc")}</ListItem>
        </List>
      </Flex>
    </>
  );
};
