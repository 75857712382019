import { Flex, Image } from "@chakra-ui/react";
import LogoContainer from "Components/QuestionContainer/LogoContainer";
import Close from "assets/X.svg";
import { useNavigate } from "react-router-dom";

const PasswordModal = ({ children }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
  };

  return (
    <Flex
      bg="#fdf6ad"
      width="100%"
      height="100%"
      borderRadius="10px"
      minH={{ base: "100vh", xl: "auto" }}
      flexDirection="column"
      justifyContent={{ base: "flex-start", xl: "center" }}
      alignItems="center"
    >
      <Flex
        style={{
          height: "40px",
          width: "100%",
          justifyContent: "flex-end",
        }}
        onClick={handleClose}
        hideFrom={"xl"}
      >
        <Image
          src={Close}
          alt={"close"}
          style={{
            height: "20px",
            margin: "10px",
          }}
        />
      </Flex>

      <Flex className="login-logo-container" width="30%" hideBelow={"xl"}>
        <LogoContainer />
      </Flex>
      {children}
    </Flex>
  );
};

export default PasswordModal;
// add useless comment to trigger a build