import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  Text,
  InputLeftElement,
  Spinner,
  useToast,
  VStack,
  Image,
} from "@chakra-ui/react";
import PasswordModal from "./PasswordModal";
import { useEffect, useState } from "react";
import { LockIcon } from "@chakra-ui/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import GoogleIcon from "assets/Google icon.svg";
import AppleIcon from "assets/Apple.svg";
import { useTranslation } from "react-i18next";
import SignupModal from "Components/Modal/SignupModal";
import { useDispatch, useSelector } from "react-redux";
import { request } from "util/request";
import {
  loginUser,
  loginEventTrigger,
  setInvitationToken,
  setPlatformToken,
} from "RTK/Slice/userSlice";
import { redirectToProvider } from "util/util";
import LinkExpiredModal from "Components/Modal/LinkedExpiredModal";

const SetPassword = () => {
  const { breakpoint } = useSelector((state: any) => state.user);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const invitation_token = searchParams.get("invitation_token");
  const platform_token = searchParams.get("platform_token");
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const toast = useToast({
    position: "top",
    status: "error",
    duration: 8000,
  });
  // const extractErrorCode = () => {
  //   const fragment = window.location.hash.substring(1);
  //   const params = new URLSearchParams(fragment);
  //   const code = params.get("error_code") || "";
  //   const invitation = params.get("invitation_token") || "";
  //   console.log(params.get("email"))
  //   if (invitation) {
  //     dispath(setInvitationToken(invitation));
  //   }
  //   const platform = params.get("platform_token") || "";
  //   if (platform) {
  //     dispath(setPlatformToken(platform));
  //   }
  //   setErrorCode(code);
  // };

  useEffect(() => {
    // extractErrorCode();
    if (errorCode == "403") {
      setDisplayModal(true);
    } else {
      setDisplayModal(false);
    }
  }, [errorCode]);

  const handleOAuthLogin = async (provider: string) => {
    try {
      setLoading(true);
      redirectToProvider(provider, invitation_token, platform_token);
      localStorage.setItem("Oauth", "true");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const checkInputValue = () => {
    if (password !== confirmPassword || password === "") {
      setDisplayMessage(true);
      return false;
    }
    return true;
  };

  const handleNavigate = () => {
    navigate("/login");
  };

  const validateConfirm = async () => {
    if (!checkInputValue()) {
      return;
    } else {
      try {
        setLoading(true);
        console.log(email);
        const { data } = await request("post", "sign_up_by_email", {
          email,
          password,
          invitation_token,
          platform_token,
        });
        setLoading(false);
        if (!data) {
          setDisplayMessage(true);
        } else {
          setDisplayMessage(false);
          setOpen(true);
          dispatch(loginUser(data));
          dispatch(loginEventTrigger(true));
          localStorage.setItem("access_token", data.access_token);
          navigate("/");
        }
      } catch (error: any) {
        if (error.response.status === 409) {
          toast({ description: "You have already registered" });
        }
        setLoading(false);
      }
    }
  };

  return (
    <Box h={"100%"} width={"100%"}>
      <PasswordModal>
        {loading && <Spinner />}
        <VStack spacing={4} align="stretch" width={{ base: "90%", xl: "70%" }}>
          <FormControl isInvalid={!!displayMessage}>
            <VStack spacing={4} align="stretch">
              <Text
                fontSize="2rem"
                textAlign={{ base: "center", xl: "left" }}
                fontWeight={700}
              >
                {t("SetPassword")}
              </Text>
              <FormErrorMessage>{displayMessage}</FormErrorMessage>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="black" />
                </InputLeftElement>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("PassowrdPlaceholder")}
                  background={"#d0f979"}
                  required={true}
                />
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="black" />
                </InputLeftElement>
              </InputGroup>
              <InputGroup>
                <Input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder={t("ComfirmPassword")}
                  background={"#d0f979"}
                  required={true}
                />
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="black" />
                </InputLeftElement>
              </InputGroup>
              {displayMessage && <Text>{t("passwordError")}</Text>}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  backgroundColor={"#d2cffc"}
                  onClick={validateConfirm}
                  h={"60px"}
                  width={"30%"}
                  border={"1px solid #000"}
                  fontWeight={800}
                >
                  {t("Confirm")}
                </Button>
              </Box>
            </VStack>
          </FormControl>

          <Flex justify="flex-start" width="100%">
            <Flex direction={"row"}>
              <Text>{t("AlreadyHaveAccount")}</Text>
              <Text
                onClick={handleNavigate}
                cursor="pointer"
                marginLeft={"5px"}
              >
                <Link to={"/login"}>
                  <Text color={"#A020F0"}>{t("Login")}</Text>
                </Link>
              </Text>
            </Flex>
          </Flex>

          <Flex align="center" width="100%">
            <Box flex="1" height="1px" bgColor="black"></Box>
            <Text mx={20} fontWeight={800}>
              {t("Or")}
            </Text>
            <Box flex="1" height="1px" bgColor="black"></Box>
          </Flex>
          <Flex
            direction={breakpoint === "xl" ? "row" : "column"}
            justify={"space-between"}
            gap={"10%"}
          >
            <Button
              border={"1px solid #000"}
              leftIcon={<Image src={GoogleIcon} />}
              backgroundColor={"#d2cffc"}
              onClick={() => handleOAuthLogin("google")}
              h={"60px"}
              flex={1}
              fontWeight={600}
              style={{ padding: breakpoint === "xl" ? 0 : "10px" }}
            >
              {t("Google")}
            </Button>
            <Button
              border={"1px solid #000"}
              backgroundColor={"#d2cffc"}
              leftIcon={<Image src={AppleIcon} />}
              onClick={() => handleOAuthLogin("apple")}
              h={"60px"}
              flex={1}
              fontWeight={600}
              style={{ padding: breakpoint === "xl" ? 0 : "10px" }}
            >
              {t("Apple")}
            </Button>
          </Flex>
        </VStack>
      </PasswordModal>
      <SignupModal open={open} setOpen={setOpen} />
      <LinkExpiredModal
        open={displayModal}
        setOpen={setDisplayModal}
        email={email || ""}
      />
    </Box>
  );
};

export default SetPassword;
