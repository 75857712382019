import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Cat from "assets/cat.svg";
import SideEyeing from "assets/side-eyeing-cloe.svg";

const CopyImageButton = () => {
  const [enter, setEnter] = useState(false);
  const [placeholder, setPlaceHolder] = useState("Copy Image");
  const { t } = useTranslation();

  useEffect(() => {
    if (enter) {
      setPlaceHolder(`${t("PasteImage")}`);
    } else {
      setPlaceHolder(`${t("CopyImage")}`);
    }
  }, [enter, t]);

  return (
    <Box
      onMouseEnter={() => setEnter(true)}
      onMouseLeave={() => setEnter(false)}
      width="100%"
      borderRadius="10px"
      backgroundColor="#D2CFFC"
      display="flex"
      justifyContent="center"
      height="72px"
      cursor="pointer"
      alignItems="center"
      hideBelow={'xl'}
    >
      <Flex alignItems="center" gap="60px">
        <Image boxSize="70px" src={enter ? SideEyeing : Cat} />
        <Text fontSize="30px" fontWeight="bold">
          {placeholder}
        </Text>
      </Flex>
    </Box>
  );
};

export default CopyImageButton;
