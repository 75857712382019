import React, { FC } from "react";
import { Flex } from "antd";
import _ from "lodash";
import { QuestionList } from "./QuestionList";

interface ChatItem {
  id: string;
  question: string;
  learner_mode: string;
  thumbnail_str: string;
}

interface QuestionContainerProps {
  onCloseDrawer?: () => void;
  groupChatHistory:
    | {
    [dateString: string]: ChatItem[];
  }
    | ChatItem[];
}

const QuestionContainer: FC<QuestionContainerProps> = ({
                                                         groupChatHistory,
                                                         onCloseDrawer
                                                       }) => {
  // const { storeChatId, storeMode } = useContext(AuthContext);
  // const navigate = useNavigate();

  // const TagIcon = (mode) => {
  //   return mode ? (
  //     <img style={{ width: "35px", height: "35px" }} src={thisIsFine} />
  //   ) : (
  //     <img style={{ width: "35px", height: "35px" }} src={computerGuy} />
  //   );
  // };

  return (
    <Flex gap="middle" vertical>
      {Array.isArray(groupChatHistory) && (
        <Flex gap="middle" vertical>
          <QuestionList
            questions={groupChatHistory}
            onCloseDrawer={onCloseDrawer}
          />
        </Flex>
      )}

      {!_.isArray(groupChatHistory) &&
        _.map(groupChatHistory, (records, timeFrame) => {
          return (
            <div key={timeFrame} style={{ paddingRight: "10px" }}>
              <div style={{ paddingBottom: "5px" }}>{timeFrame}</div>
              <Flex gap="middle" vertical>
                <QuestionList questions={records} onCloseDrawer={onCloseDrawer} />
              </Flex>
            </div>
          );
        })}
    </Flex>
  );
};

export default QuestionContainer;
