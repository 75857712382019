import LogRocket from "logrocket";
import AppLayout from "Components/Layout";
import PollingComponent from "Components/Polling/PollingInvitation";
import "App.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserProfiles,
  setCredit,
  setBreakPoint,
  loginEventTrigger,
  updateSubscription,
} from "RTK/Slice/userSlice";
import {
  getDailyBonus,
  getUserCredit,
  getUserInfo,
  getSubscriptionInfo,
} from "util/userRequest";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "theme";
import BonusModal from "Components/Modal/BonusModal";
import { setIsClickSubscribe } from "RTK/Slice/modalSlice";

LogRocket.init("adwdoj/mathsolver-prod");

function App() {
  const [isInvited, setIsInvited] = useState(false);
  const [isNotifiedDaily, setIsNotifiedDaily] = useState(false);
  const dispatch = useDispatch();
  const { user, loginEvent } = useSelector((state) => state.user);

  useEffect(() => {
    if (loginEvent || localStorage.getItem("Oauth")) {
      if (!user.user_info) {
        userInfo();
      }
      if (user?.user_info?.is_valid_for_new) {
        setIsInvited(true);
        refreshCredit();
      }
      refreshCredit();
      userSubscription();
      daily_bonus(user?.user_info?.user_id);
      dispatch(loginEventTrigger(false));
      localStorage.removeItem("Oauth");
      if (localStorage.getItem("isClickSubscribe") == "true") {
        dispatch(setIsClickSubscribe());
      } else {
        localStorage.removeItem("isClickSubscribe");
      }
    }
  }, [loginEvent]);

  const refreshCredit = async () => {
    const result = await getUserCredit();
    dispatch(setCredit(result.data));
  };

  const userSubscription = async () => {
    if (user?.user_info) {
      const { result } = await getSubscriptionInfo();
      dispatch(updateSubscription(result));
    }
  };

  const userInfo = async () => {
    const data = await getUserInfo();
    dispatch(updateUserProfiles(data));
    if (data?.is_valid_for_new) {
      setIsInvited(true);
      refreshCredit();
    }
    daily_bonus(data?.user_id);
  };

  const daily_bonus = async (user_id) => {
    if (user_id) {
      const result = await getDailyBonus(user_id);
      if (result.data) {
        refreshCredit();
        setIsNotifiedDaily(true);
      }
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let currentBreakpoint = "xl"; // default to xl

      if (screenWidth < 1280) {
        currentBreakpoint = "mobile";
      }
      dispatch(setBreakPoint(currentBreakpoint));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <ChakraProvider theme={theme}>
      <div className="body">
        <BonusModal
          open={isNotifiedDaily}
          setOpen={setIsNotifiedDaily}
          message={"You get daily bonus!"}
        />
        <BonusModal
          open={isInvited}
          setOpen={setIsInvited}
          message={"You and Your Friends get bonus!"}
        />
        <PollingComponent />
        <BrowserRouter>
          <AppLayout />
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
}

export default App;
