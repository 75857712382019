import PasswordModal from "./PasswordModal";
import LeoLaugh from "assets/leo-laughing.svg";
import { useTranslation } from "react-i18next";
import {
  Box,
  Heading,
  Text,
  Image,
  VStack, Flex
} from "@chakra-ui/react";

const CheckEmail = () => {
  const { t } = useTranslation();
  return (
    <Box h={'100%'} w={'100%'}>
      <PasswordModal>
        <VStack spacing={4} align="stretch">
          <Box textAlign={{base:'center',xl:'left'}}>
            <Text fontSize={{base:'32px', xl:'16px'}} fontWeight={700}>{t("CheckEmail")}</Text>
          </Box>
          <Box textAlign="left">
            <Text px={{base:'1rem',xl:0}} fontSize={'18px'} fontWeight={500}>{t("CheckEmailMessage")}</Text>
          </Box>
          <Flex justifyContent="center" p={5}>
            <Image src={LeoLaugh} w={{base:'100%', xl:'auto'}}/>
          </Flex>
        </VStack>
      </PasswordModal>
    </Box>
  );
};

export default CheckEmail;
