import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { EmailIcon, LockIcon } from "@chakra-ui/icons";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PasswordModal from "./PasswordModal";
import GoogleIcon from "assets/Google icon.svg";
import AppleIcon from "assets/Apple.svg";
import { request } from "util/request";
import { loginEventTrigger, loginUser } from "RTK/Slice/userSlice";
import { redirectToProvider } from "util/util";

const Login = () => {
  const { breakpoint, user } = useSelector((state: any) => state.user);
  const [input, setInput] = useState({ email: "", password: "" });
  const [displayMessage, setDisplayMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { invitation_token, platform_token } = useSelector(
    (state: any) => state.user
  );

  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    status: "success",
    duration: 8000,
  });

  useEffect(() => {
    if (invitation_token) {
      toast({ description: "Invitation token found!" });
    }
    if (user.access_token) {
      toast({ description: "Session expired, please login again" });
    }
  }, []);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const checkInputValue = () => {
    if (input.email === "" || input.password === "") {
      setDisplayMessage("email and password cannot be empty");
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    if (!checkInputValue()) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await request("post", "login", {
        email: input.email,
        password: input.password,
      });
      setLoading(false);
      dispatch(loginUser(data));
      dispatch(loginEventTrigger(true));
      localStorage.setItem("access_token", data.access_token);
      navigate("/");
    } catch (error) {
      setLoading(false);
      setDisplayMessage("please check your credential");
      console.log(error);
    }
  };

  const handleOAuthLogin = async (provider: string) => {
    try {
      redirectToProvider(provider, invitation_token, platform_token);
      dispatch(loginEventTrigger(true));
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePassModal = () => {
    navigate("/forgetPassword");
  };

  return (
    <Box h={"100%"} width={"100%"}>
      <PasswordModal>
        {loading && <Spinner />}
        <VStack spacing={4} align="stretch" width={{ base: "90%", xl: "70%" }}>
          <FormControl isInvalid={!!displayMessage}>
            <VStack spacing={4} align="stretch">
              <Text
                fontSize="2rem"
                textAlign={{ base: "center", xl: "left" }}
                fontWeight={700}
              >
                {t("SignIn")}
              </Text>
              <FormErrorMessage>{displayMessage}</FormErrorMessage>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="black" />
                </InputLeftElement>
                <Input
                  type="email"
                  value={input.email}
                  onChange={(e) =>
                    setInput({ ...input, email: e.target.value })
                  }
                  placeholder="Email"
                  background={"#d0f979"}
                  required={true}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <LockIcon color="black" />
                </InputLeftElement>
                <Input
                  type="password"
                  value={input.password}
                  onChange={(e) =>
                    setInput({ ...input, password: e.target.value })
                  }
                  onKeyDown={handleEnter}
                  placeholder="Password"
                  background={"#d0f979"}
                  required={true}
                />
              </InputGroup>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  backgroundColor={"#d2cffc"}
                  onClick={handleLogin}
                  h={"60px"}
                  width={"30%"}
                  border={"1px solid #000"}
                  fontWeight={800}
                >
                  {t("SignIn")}
                </Button>
              </Box>
            </VStack>
          </FormControl>

          <Flex justify="space-between" width="100%">
            <Flex
              direction={"row"}
              display={"flex"}
              justify={"flex-start"}
              width={"max-content"}
            >
              <Text style={{ display: "flex" }}>
                {t("HaveAccount")}
                <Text cursor="pointer" marginLeft={"5px"}>
                  <Link to={"/signup"}>
                    <Text color={"#A020F0"}>{t("Signup")}</Text>
                  </Link>
                </Text>
              </Text>
            </Flex>

            <Text onClick={handlePassModal} cursor="pointer">
              {t("ForgetPassword")}
            </Text>
          </Flex>
          <Flex align="center" width="100%">
            <Box flex="1" height="1px" bgColor="black"></Box>
            <Text mx={20} fontWeight={800}>
              {t("Or")}
            </Text>
            <Box flex="1" height="1px" bgColor="black"></Box>
          </Flex>
          <Flex
            direction={breakpoint === "xl" ? "row" : "column"}
            justify={"space-between"}
            gap={"10%"}
          >
            <Button
              border={"1px solid #000"}
              leftIcon={<Image src={GoogleIcon} />}
              backgroundColor={"#d2cffc"}
              onClick={() => handleOAuthLogin("google")}
              h={"60px"}
              flex={1}
              fontWeight={600}
              style={{ padding: breakpoint === "xl" ? 0 : "10px" }}
            >
              {t("Google")}
            </Button>
            <Button
              border={"1px solid #000"}
              backgroundColor={"#d2cffc"}
              leftIcon={<Image src={AppleIcon} />}
              onClick={() => handleOAuthLogin("apple")}
              h={"60px"}
              flex={1}
              fontWeight={600}
              style={{ padding: breakpoint === "xl" ? 0 : "10px" }}
            >
              {t("Apple")}
            </Button>
          </Flex>
        </VStack>
      </PasswordModal>
    </Box>
  );
};

export default Login;
