import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Button,
  ButtonGroup,
  Grid,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { BasicPlan } from "./BascisPlan";
import { MonthlyPlan } from "./MonthlyPlan";
import SubBg from "assets/salt-bae.svg";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetIsClickSubscribe } from "RTK/Slice/modalSlice";

export const SubscribeModel = ({ onClose, isOpen }) => {
  const { breakpoint } = useSelector((state: any) => state.user);
  const dispatch = useDispatch()
  const [subscribePlan, setSubscribePlan] = useState<"Quarterly" | "Monthly">(
    "Monthly"
  );

  const activeButtonStyle = {
    background: "#CBEE4C",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "32px",
  };

  const handleModalClose= () => {
    dispatch(resetIsClickSubscribe());
    onClose();
  }

  return (
    <>
      <Modal onClose={handleModalClose} isOpen={isOpen} isCentered size={"lg"}>
        <ModalOverlay />
        <ModalContent
          pt={"29px"}
          minW={breakpoint === "mobile" ? "100%" : ["357px", "798px"]}
        >
          <ModalCloseButton />
          <ModalBody pb={"50px"} px={"67px"}>
            <Box textAlign={"center"}>
              <Text fontWeight={700} fontSize={"24px"}>
                {t("subscribe.now")}
              </Text>
              {/* <Text mt={4} fontWeight={700} fontSize={"16px"}>{t("subscribe.subtitle")}</Text> */}
              {/* choose plan */}
              <Flex justifyContent={"center"} w={"100%"} mt={4}>
                <ButtonGroup
                  isAttached
                  borderRadius="32px"
                  fontWeight={400}
                  background={"rgba(203, 238, 76, 0.5)"}
                >
                  <Button
                    borderRadius={"32px"}
                    onClick={() => setSubscribePlan("Quarterly")}
                    style={
                      subscribePlan === "Quarterly"
                        ? activeButtonStyle
                        : { background: "initial" }
                    }
                  >
                    {t("subscribe.quarterly")}
                  </Button>
                  <Button
                    onClick={() => setSubscribePlan("Monthly")}
                    borderRadius={"32px"}
                    style={
                      subscribePlan === "Monthly"
                        ? activeButtonStyle
                        : { background: "initial" }
                    }
                  >
                    {t("subscribe.monthly")}
                  </Button>
                </ButtonGroup>
              </Flex>
            </Box>

            <Grid
              mt={10}
              gap={4}
              gridTemplateColumns={
                breakpoint === "mobile" ? ["1fr", "1fr"] : ["1fr", "1fr 1fr"]
              }
              pos={"relative"}
            >
              <Image
                src={SubBg}
                hideBelow={"md"}
                w={"156px"}
                h={"156px"}
                pos={"absolute"}
                top={"-78px"}
                right={"-28px"}
              />
              {/*basic plan*/}
              <BasicPlan />

              {/*  monthly plan*/}
              <MonthlyPlan subscribePlan={subscribePlan} />
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
