import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useEffect, useState } from "react";
import Coin from "assets/coin.svg";
import InvitationModal from "./InvitationModal";
import TokenOut from "assets/TokenOut.svg";
import { SubscribeModel } from "./SubscribeModel";
import { useDisclosure } from "@chakra-ui/react";
import { PaymentSuccess } from "./SubscribeModel/PaymentSuccess";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "util/userRequest";
import { useDispatch, useSelector } from "react-redux";
import { updateSubscription, updateUserProfiles } from "RTK/Slice/userSlice";
import { handleTokenModal } from "RTK/Slice/modalSlice";
import { getUserCredit } from "util/userRequest";
import { setCredit } from "RTK/Slice/userSlice";

interface TOkenModalProps {
  shouldClose?: boolean;
}

const TokenOutModal: FC<TOkenModalProps> = ({ shouldClose = false }) => {
  const {
    isOpen: isOpenSubscribeModal,
    onOpen: openSubscribeModal,
    onClose: closeSubscribeModal,
  } = useDisclosure();
  const {
    isOpen: isOpenPaymentSuccess,
    onOpen: openPyamentSuccess,
    onClose: cloasePaymentSuccess,
  } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openInvitation, setOpenInvitation] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [close, setClose] = useState(0);
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const tokenModal = useSelector((state: any) => state.modal.tokenModal);
  const { temp_credit, perm_credit, user } = useSelector(
    (state: any) => state.user
  );
  const totalCredits = temp_credit + perm_credit;

  const refreshCredit = async () => {
    if (user?.user_info?.user_id) {
      const result = await getUserCredit();
      dispatch(setCredit(result?.data));
    }
  };

  useEffect(() => {
    refreshCredit();
  }, []);

  const UserInfo = useCallback(async () => {
    try {
      const data = await getUserInfo();
      dispatch(updateUserProfiles(data));
      dispatch(updateSubscription(true));
    } catch (error) {
      console.error("Failed to fetch user info", error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (queryParams?.payment === "success") {
      closeSubscribeModal();
      openPyamentSuccess(); // Open PaymentSuccess modal
      dispatch(handleTokenModal(false));
    }
  }, [closeSubscribeModal, openPyamentSuccess, queryParams?.payment]);

  const handleClosePaymentSuccess = () => {
    cloasePaymentSuccess();
    UserInfo();
    navigate(location.pathname, { replace: true });
  };

  return (
    <>
      <InvitationModal
        open={openInvitation}
        onClose={() => setOpenInvitation(false)}
      />
      <Modal
        open={tokenModal}
        onCancel={() => {
          dispatch(handleTokenModal(false));
          setDisplayMessage(false);
          shouldClose && navigate("/");
        }}
        width={"600px"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              marginBottom: "20px",
            }}
          >
            <img src={Coin} alt={"coin"} />
            <span
              style={{
                marginLeft: "5px",
                fontSize: "large",
                fontWeight: "600",
              }}
            >
              {t("Oops")}
            </span>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <img src={TokenOut} alt={"tokenOut"} style={{ width: "100%" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Coin}
              alt={"coin"}
              style={{ width: "51px", height: "51px", margin: "10px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "large", fontWeight: "600" }}></span>
            <span style={{ fontSize: "large", fontWeight: "600" }}>
              {t("YourCurrentCoinBalance", { totalCredits })}
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: "1",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid black",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "#D2CFFC",
            fontSize: "large",
            fontWeight: "600",
            marginTop: "20px",
            marginLeft: "0",
            cursor: "pointer",
          }}
          onClick={openSubscribeModal}
        >
          <span>{t("Subscribe MathSolver")}</span>
        </div>
        <div
          style={{
            display: "flex",
            flex: "1",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid black",
            padding: "10px",
            borderRadius: "10px",
            backgroundColor: "#D2CFFC",
            fontSize: "large",
            fontWeight: "600",
            marginTop: "20px",
            marginLeft: "0",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(handleTokenModal(false));
            setDisplayMessage(false);
            setOpenInvitation(true);
            shouldClose && navigate("/");
          }}
        >
          <span>{t("InviteFriend")}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "20px",
            fontSize: "large",
            fontWeight: "600",
            visibility: `${displayMessage ? "visible" : "hidden"}`,
          }}
        >
          {t("ConfirmCopy")}
          <img
            src={Coin}
            style={{
              marginLeft: "10px",
            }}
            alt={"coin"}
          />
        </div>
      </Modal>
      <SubscribeModel
        isOpen={isOpenSubscribeModal}
        onClose={closeSubscribeModal}
      />
      <PaymentSuccess
        isOpen={isOpenPaymentSuccess}
        onClose={handleClosePaymentSuccess}
      />
    </>
  );
};

export default TokenOutModal;
