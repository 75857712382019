import { FC, memo, useMemo, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Grid,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Image,
  Text,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  UsFlag,
  FrFlag,
  DeFlag,
  CnFlag,
  EsFlag,
  KrFlag,
  UaFlag,
  RuFlag,
  AeFlag,
  PtFlag,
  InFlag,
  JpFlag,
} from "assets/lang";
import { useTranslation } from "react-i18next";

type LanguageSelectModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const LanguageBox = ({ icon, text, i18n, onClose }) => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  localStorage.setItem("language", language);
  return (
    <Flex
      w={"120px"}
      h={["43px"]}
      border="1px solid"
      borderRadius={"5px"}
      bg={"#D2CFFC"}
      fontWeight={500}
      fontSize={"14px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderColor={"black"}
      px={"6px"}
      onClick={() => {
        changeLanguage(i18n);
        onClose();
      }}
      opacity={language === i18n ? "100%" : "40%"}
      _hover={{ cursor: "pointer" }}
      textAlign={"center"}
    >
      <Image src={icon} width={"30px"} height={"20px"} />
      <Text minW={"70px"}>{text}</Text>
    </Flex>
  );
};

const LanguageSelectModal: FC<LanguageSelectModalProps> = memo(
  ({ isOpen, onClose }) => {
    const [searchContent, setSearchContent] = useState("");
    const languages = [
      { icon: UsFlag, text: "English", i18n: "EN" },
      { icon: FrFlag, text: "French", i18n: "FR" },
      { icon: DeFlag, text: "German", i18n: "DE" },
      { icon: CnFlag, text: "Chinese", i18n: "ZH" },
      { icon: EsFlag, text: "Spanish", i18n: "ES" },
      { icon: KrFlag, text: "Korean", i18n: "KR" },
      { icon: UaFlag, text: "Ukrainian", i18n: "UA" },
      { icon: RuFlag, text: "Russian", i18n: "RU" },
      { icon: PtFlag, text: "Portuguese", i18n: "PT" },
      { icon: AeFlag, text: "Arbic", i18n: "AE" },
      { icon: InFlag, text: "Hindi", i18n: "IN" },
      { icon: JpFlag, text: "Japanese", i18n: "JA" },
    ];

    const searchResults = useMemo(() => {
      return searchContent
        ? languages.filter((l) => {
            const regex = new RegExp(searchContent, "i");
            return regex.test(l.text);
          })
        : languages;
    }, [languages, searchContent]);

    return (
      <>
        <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
          <ModalOverlay />
          <ModalContent pt={"29px"}>
            <ModalCloseButton />
            <ModalBody pb={"50px"}>
              <InputGroup h={"24px"} display={["none", "flex"]}>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="black" />
                </InputLeftElement>
                <Input
                  bg={"#D0F979"}
                  border={"1px solid"}
                  borderColor={"black"}
                  onChange={(e) => setSearchContent(e.target.value)}
                />
              </InputGroup>

              <Grid
                gridTemplateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]}
                rowGap={"20px"}
                columnGap={"30px"}
                w={"100%"}
                mt={"30px"}
              >
                {searchResults.map((l, index) => (
                  <LanguageBox
                    text={l.text}
                    icon={l.icon}
                    i18n={l.i18n}
                    key={index}
                    onClose={onClose}
                  />
                ))}
              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default LanguageSelectModal;
