import thisIsFine from "../../../assets/this-is-fine.svg";
import Coin from "../../../assets/coin.svg";
import Credit from "../../../assets/credit.svg";
import { Flex, Avatar, Text, Image } from "@chakra-ui/react";
import Premium from "assets/PREMIUM.svg";
import Unlimited from "assets/Unlimited+.svg";

const UserInfo = ({ user, temp_credit, perm_credit, isFreeUser }) => {
  return (
    <div>
      <Flex
        wrap="wrap"
        justify="space-between"
        px={[0, "10px"]}
        pb={["10px", 0]}
      >
        <Flex align="center" justify="center">
          <Flex align={"center"} flexDirection={"column"}>
            <Avatar
              width={"35px"}
              height={"35px"}
              src={user?.avatar_url ? user.avatar_url : thisIsFine}
              bg={["gray", "rgba(0, 0, 0, 0.25)"]}
              icon={
                <Image
                  boxSize="35px"
                  src={user?.avatar_url ? user?.avatar_url : thisIsFine}
                  alt="it's fine"
                />
              }
            />
            {!isFreeUser && (
              <Image
                boxSize="50px"
                src={Premium}
                alt="banner"
                position={"absolute"}
              />
            )}
          </Flex>

          <Text ml="10px" fontWeight="700" fontSize={"12px"}>
            {user?.user_info?.name?.split(" ")[0] ||
              user?.user_info?.email?.split("@")[0] ||
              "You"}
          </Text>
        </Flex>

        <Flex align="center" justify="center" fontSize={"12px"} gap={"5px"}>
          <Flex align="center" justify="center">
            <Text m="2px">{temp_credit || 0}</Text>
            <Image src={Coin} alt="credit" />
          </Flex>
          <Flex align="center" justify="center">
            {!isFreeUser && <Image src={Unlimited} alt="unlimited" />}
            <Text m="2px">{perm_credit || 0}</Text>
            <Image src={Credit} alt="coin" />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default UserInfo;
