import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "locale/en.json";
import ZH from "locale/cn.json";
import DE from "locale/de.json";
import ES from "locale/es.json";
import KR from "locale/kr.json";
import UA from "locale/ua.json";
import RU from "locale/ru.json";
import PT from "locale/pt.json";
import AE from "locale/ae.json";
import IN from "locale/in.json";
import FR from "locale/fr.json";
import JA from "locale/jp.json";
const savedLanguage = localStorage.getItem('language') || 'EN';
i18n.use(initReactI18next).init({
  resources: {
    EN: { ...EN },
    ZH: { ...ZH },
    DE: { ...DE },
    AE: { ...AE },
    ES: { ...ES },
    KR: { ...KR },
    UA: { ...UA },
    RU: { ...RU },
    PT: { ...PT },
    IN: { ...IN },
    FR: { ...FR },
    JA: { ...JA },
  },
  lng: savedLanguage,
  interpolation: {
    escapeValue: false
  }
});
