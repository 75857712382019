import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../Slice/userSlice";
import fileReducer from "../Slice/fileSlice";
import modalReducer from "../Slice/modalSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  user: userReducer,
  file: fileReducer,
  modal: modalReducer,
});
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"], // 需要缓存的白名单,不设置则全部缓存
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});
export const persistor = persistStore(store);
