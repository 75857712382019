import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Flex,
  Image,
  ModalCloseButton,
} from "@chakra-ui/react";
import credit from "assets/credit_green.svg";
import Doge from "assets/Graphic.svg";
import { useEffect } from "react";
import { getSubscriptionInfo } from "util/userRequest";
import { useDispatch, useSelector } from "react-redux";
import { updateSubscription } from "RTK/Slice/userSlice";

export const PaymentSuccess = ({ onClose, isOpen }) => {
  const { user } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const getSubInfo = async () => {
    if (user?.user_info?.user_id) {
      const { result } = await getSubscriptionInfo();
      dispatch(updateSubscription(result));
    }
  };

  useEffect(() => {
    getSubInfo();
  }, [isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent pt={"29px"} minW={["357px", "595px"]}>
        <ModalCloseButton />
        <ModalBody pb={"53px"} px={"67px"}>
          <Flex justifyContent={"center"}>
            <Image src={credit} alt="credit image" />
            <Text fontWeight={700} ml={3}>
              Your payment is successful!
            </Text>
          </Flex>
          <Image src={Doge} alt="doge image" />
          <Text
            fontWeight={700}
            textAlign={"center"}
            fontSize={["12px", "15px"]}
          >
            Now you can enjoy the premium service!
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
