import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const useUpdateShareMode = () => {
  const location = useLocation();
  const [isShareMode, setIsShareMode] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.startsWith("/shared_chat/")) {
      setIsShareMode(true);
    } else {
      setIsShareMode(false);
    }

  }, [location, setIsShareMode]);

  return {
    isShareMode
  };
};
