import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Flex,
  Text,
  Button,
  ButtonGroup,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeCheckPremiumModal } from "RTK/Slice/modalSlice";
import { updateSubscription } from "RTK/Slice/userSlice";
import { getCustomerInfo, getSubscriptionInfo } from "util/userRequest";

interface CheckPremiumModalProps {
  open: boolean;
  onClose: () => void;
}

type paymentDetailsProps = {
  next_payment_date: Date | null;
  card_last4: string;
  card_expiry: string;
  email: string;
  subscription_plan: string;
  subscription_price: number;
  subscription_status: string;
  billing_cycle: string;
  is_canceled: boolean;
};

const CheckPremiumModal: FC<CheckPremiumModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [subscribePlan, setSubscribePlan] = useState<string>("");
  const [billing_cycle, setBillingCycle] = useState<string>("");
  const [subscription_price, setSubscribePrice] = useState<string>("");
  const { user } = useSelector((state: any) => state.user);
  const [paymentDetails, setPaymentDetails] = useState<paymentDetailsProps>({
    next_payment_date: null,
    card_last4: "",
    card_expiry: "",
    email: "",
    subscription_plan: "",
    subscription_price: 0,
    subscription_status: "",
    billing_cycle: "",
    is_canceled: false,
  });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { checkPremModal } = useSelector((state: any) => state.modal);
  const origin = window.location.origin;
  const isDev =
    origin?.includes("localhost") || origin?.includes("dev") ? true : false;

  const modelSize = useBreakpointValue(
    {
      base: "full",
      xl: "lg",
    },
    {
      fallback: "md",
    }
  );

  const fetchCustomerDetails = async () => {
    setLoading(true);
    try {
      const {
        email,
        card_expiry,
        card_last4,
        next_payment_date,
        subscription_plan,
        subscription_price,
        subscription_status,
        billing_cycle,
        is_canceled,
      } = await getCustomerInfo();

      const transformed_subscription_price = subscription_price
        ? (parseInt(subscription_price, 10) / 100).toString()
        : "";
      setPaymentDetails({
        email,
        card_expiry,
        card_last4,
        next_payment_date,
        subscription_plan,
        subscription_price,
        subscription_status,
        billing_cycle,
        is_canceled,
      });
      setSubscribePlan(subscription_plan);
      setBillingCycle(billing_cycle);
      setSubscribePrice(transformed_subscription_price);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user?.user_info?.package !== "free" && checkPremModal) {
      fetchCustomerDetails();
    }
  }, []);

  const updateUserSubscriptions = async () => {
    const { result } = await getSubscriptionInfo();
    console.log(result);
    if (result) {
      dispatch(updateSubscription(true));
    } else {
      dispatch(updateSubscription(false));
    }
    dispatch(closeCheckPremiumModal());
  };

  return (
    <Modal
      isOpen={checkPremModal}
      // onClose={updateUserSubscriptions}
      onClose={() => {
        /* do nothing */
      }}
      size={modelSize}
    >
      <ModalOverlay />
      <ModalContent maxW={{ base: "100%", xl: "600px" }}>
        <ModalHeader py={"20px"}>
          <Flex
            justifyContent="center"
            alignItems="center"
            mt={{ base: "20px", xl: "0" }}
          >
            <Text ml={2} fontSize="lg" fontWeight="bold">
              {t("Your Plan: Premium")}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton onClick={updateUserSubscriptions} />
        <ModalBody pb={"20px"}>
          {/* <Flex justifyContent={"center"} w={"100%"} mt={4}>
            <ButtonGroup
              isAttached
              borderRadius="32px"
              fontWeight={400}
              background={"rgba(203, 238, 76, 0.5)"}
            >
              <Button
                borderRadius={"32px"}
                sx={{ cursor: "default" }} // This removes the pointer cursor on hover
                style={
                  billing_cycle === "Quarterly"
                    ? activeButtonStyle
                    : { background: "initial" }
                }
              >
                {t("subscribe.quarterly")}
                {billing_cycle === "Quarterly" && " (current)"}
              </Button>
              <Button
                borderRadius={"32px"}
                sx={{ cursor: "default" }} // This removes the pointer cursor on hover
                style={
                  billing_cycle === "Monthly"
                    ? activeButtonStyle
                    : { background: "initial" }
                }
              >
                {t("subscribe.monthly")}
                {billing_cycle === "Monthly" && " (current)"}
              </Button>
            </ButtonGroup>
          </Flex> */}
          <Flex justifyContent={"center"} w={"100%"} mt={4}>
            <Button
              borderRadius={"32px"}
              fontWeight={400}
              background={"rgba(203, 238, 76, 0.5)"}
              sx={{ cursor: "default" }} // This removes the pointer cursor on hover
            >
              {billing_cycle === "Quarterly"
                ? `${t("subscribe.quarterly")} (current)`
                : `${t("subscribe.monthly")} (current)`}
            </Button>
          </Flex>

          <Flex direction="column" my={"20px"} pl={"20px"}>
            <Text ml={2}>
              {paymentDetails.is_canceled
                ? t("You have canceled the service")
                : t(
                    "Your next {{billing_cycle}} payment of $ {{subscription_price}} (not including tax) will be charged on {{next_payment_date}}",
                    {
                      billing_cycle: billing_cycle,
                      subscription_price: subscription_price,
                      next_payment_date: paymentDetails.next_payment_date,
                    }
                  )}
            </Text>
            <Link
              href={
                isDev
                  ? process.env.REACT_APP_STRIPE_PAYMENT_PORTAL_TEST_LINK
                  : process.env.REACT_APP_STRIPE_PAYMENT_PORTAL_OFFICIAL_LINK
              } // Replace with your actual link
              textDecoration="underline"
            >
              <Text ml={2}>{t("View Billing History")}</Text>
            </Link>
            <Box mt={7} mb={7} width="100%" height="1px" bg="#000000" />
            <Text ml={2} fontWeight="bold">
              {t("Payment Method")}
            </Text>
            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              my="20px"
            >
              <Text ml={2} flex="1">
                {t(`Mastercard Ending {{card_last4}}`, {
                  card_last4: paymentDetails.card_last4,
                })}
              </Text>
              <Text ml={2} flex="1" textAlign="center">
                {t(`Exp: {{card_expiry}}`, {
                  card_expiry: paymentDetails.card_expiry,
                })}
              </Text>
              <Link
                href={
                  isDev
                    ? process.env.REACT_APP_STRIPE_PAYMENT_PORTAL_TEST_LINK
                    : process.env.REACT_APP_STRIPE_PAYMENT_PORTAL_OFFICIAL_LINK
                }
                // Replace with your actual link
                textDecoration="underline"
                flex="1"
              >
                <Text ml={2} textAlign="right">
                  {t("Update Card Info")}
                </Text>
              </Link>
            </Flex>

            <Flex
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              my="20px"
            >
              <Text
                cursor="pointer"
                onClick={() => {
                  window.open(
                    `${
                      isDev
                        ? process.env.REACT_APP_STRIPE_PAYMENT_PORTAL_TEST_LINK
                        : process.env
                            .REACT_APP_STRIPE_PAYMENT_PORTAL_OFFICIAL_LINK
                    }`,

                    "_blank"
                  );
                }}
                ml={2}
                flex="1"
                color={"#FF0000"}
              >
                {t("Cancel Subscription")}
              </Text>
              <Link href="mailto:hi.mathsolver@gmail.com">
                <Text ml={2} flex="1" textAlign="right">
                  {t("Email us for other questions")}
                </Text>
              </Link>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CheckPremiumModal;
