const routes = [
  { path: "/", name: "Home" },
  { path: "/login", name: "login" },
  { path: "/forgetPassword", name: "forgetPassword" },
  { path: "/checkEmail", name: "checkEmail" },
  { path: "/resetPassword", name: "resetPassword" },
  { path: "/chat/:chatId", name: "chat" },
];

const Sitemap = () => {
  const baseUrl = "https://chat.mathsolver.top";

  const generateSitemapXml = () => {
    const urls = routes
      .map(
        (route) => `
      <url>
        <loc>${baseUrl}${route.path}</loc>
        <priority>0.8</priority>
      </url>
    `
      )
      .join("");

    return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${urls}
    </urlset>`;
  };

  return (
    <div>
      <pre>{generateSitemapXml()}</pre>
    </div>
  );
};

export default Sitemap;
