import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setChatHistory } from "RTK/Slice/userSlice";
import { getChatHistory } from "util/userRequest";
import { useTranslation } from "react-i18next";

export const useChatHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getQuestions = async () => {
    try {
      const result = await getChatHistory();
      dispatch(setChatHistory(groupChatHistory(result?.data?.data || [])));
    } catch (error) {
      console.error("Failed to fetch chat history:", error);
    }
  };
  const getDateString = (timestamp) => {
    const date = dayjs(timestamp);
    const today = dayjs();
    const yesterday = today.subtract(1, "day");

    if (date.isSame(today, "day")) {
      return t("Today");
    } else if (date.isSame(yesterday, "day")) {
      return t("Yesterday");
    } else if (date.isAfter(today.subtract(7, "days"), "day")) {
      return t("AWeek");
    } else if (date.isAfter(today.subtract(30, "days"), "day")) {
      return t("AMonth");
    } else if (date.year() === today.year()) {
      return `${date.month() + 1} ${t("FewMonth")}`;
    } else {
      return date.year() + t("Year");
    }
  };

  const groupChatHistory = (chatHistory) => {
    const groupedHistory = {};
    if (chatHistory && chatHistory.length) {
      chatHistory.forEach((record) => {
        const dateString = getDateString(record.created_at);
        if (!groupedHistory[dateString]) {
          groupedHistory[dateString] = [];
        }
        groupedHistory[dateString].push(record);
      });
    }
    return groupedHistory;
  };

  return {
    getQuestions,
  };
};
