import { Box, Flex, IconButton, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChatId } from "RTK/Slice/userSlice";
import { setStatus } from "RTK/Slice/fileSlice";
import { navigateToMain } from "util/constant";
import { Icon } from "@chakra-ui/icons";
import Logo from "assets/LOGO.svg";
import Close from "assets/Close.svg";

const Header = ({ setCollapsed, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentChatId } = useSelector((state: any) => state.user);

  const MenuIcon = (props) => (
    <Icon viewBox="0 0 35 25" {...props}>
      <path d="M2 2H33M2 12.5H33M2 23H33" stroke="black" strokeWidth="4" strokeLinecap="round"
            strokeLinejoin="round" />
    </Icon>
  );
  const handleClose = () => {
    dispatch(setCurrentChatId(""));
    navigate("/");
    dispatch(setStatus(false));
    onClose();
  };

  return (
    <Box
      position="relative"
      height="100%"
      maxWidth="100vw"
      width="100%"
      left="0"
      top="0"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <Flex
        padding="20px"
        justifyContent={currentChatId ? "space-between" : "flex-start"}
        alignItems="center"
        gap={currentChatId ? 0 : "10%"}
      >
        <IconButton
          icon={<MenuIcon />}
          onClick={() => setCollapsed(true)}
          aria-label="Menu"
          variant="ghost"
          size="sm"
        />
        <Flex alignItems="center" justifyContent="center" width="70%">
          <Image
            src={Logo}
            alt="Logo"
            maxHeight="50%"
            maxWidth="50%"
            onClick={navigateToMain}
            cursor="pointer"
          />
        </Flex>

        {currentChatId && (<Box>
          <Image
            src={Close}
            onClick={handleClose}
            aria-label="Close"
          />
        </Box>)}

      </Flex>
    </Box>
  );
};

export default Header;
