// PollingComponent.js
import InvitationCongratsModal from "Components/Modal/InvitationCongratsModal";
import { useSelector } from "react-redux";
import useReferee from "./useReferee";
const PollingComponent = () => {
  const { user } = useSelector((state: any) => state.user);
  const { referee, bonus, open, setOpen } = useReferee(
    user?.user_info?.user_id || ""
  );
  return open ? (
    <InvitationCongratsModal
      refereeList={referee}
      open={open}
      bonus={bonus}
      setOpen={setOpen}
    />
  ) : null;
};

export default PollingComponent;
