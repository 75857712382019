import { FC, memo, useMemo } from "react";
import { Button, Center, Flex, List, ListItem, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { request } from "util/request";
import { useToast } from "@chakra-ui/react";

interface MonthlyPlanProps {
  subscribePlan: "Quarterly" | "Monthly";
}

export const MonthlyPlan: FC<MonthlyPlanProps> = memo(({ subscribePlan }) => {
  const { user } = useSelector((state: any) => state.user);
  const toast = useToast();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  );
  const handleCheckout = async () => {
    const priceId =
    subscribePlan === "Monthly"
      ? process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID
      : process.env.REACT_APP_STRIPE_QUARTERLY_PRICE_ID;
    try{
      const { data } = await request("post", "create_checkout_session", {
        email: user?.email || user?.user_info?.email || "",
        priceId: priceId,
        successUrl: window.location.origin + "?payment=success",
        cancelUrl: window.location.origin + "/cancel",
      });
      const stripe = await stripePromise;
      const error = await stripe?.redirectToCheckout({
        sessionId: data.sessionId
      });
  
      if (error) {
        console.error("Error:", error);
      }
    } catch (error:any){
      if(error.response.status===409){
        toast({
          description: error.response.data.detail,
          status: "warning",
          duration: 2000,
          position:'top',
          isClosable: true,
        });
      }
      else if (error.response.status===500){
        toast({
          description: error.response.data.detail,
          status: "error",
          position:'top',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const displayContent = useMemo(() => {
    return subscribePlan === "Monthly"
      ? {
          save_percent: "30%",
          price: (
            <Trans
              i18nKey={"subscribe.monthly.price"}
              components={{ del: <del /> }}
              values={{
                originalPrice: 10,
                discountedPrice: 7,
                subPlan: "month",
              }}
            />
          ),
        }
      : {
          save_percent: "50%",
          price: (
            <Trans
              i18nKey={"subscribe.monthly.price"}
              components={{ del: <del /> }}
              values={{
                originalPrice: 30,
                discountedPrice: 15,
                subPlan: "quarter",
              }}
            />
          ),
        };
  }, [subscribePlan]);

  return (
    <>
      <Flex
        border={"1px solid black"}
        borderRadius={"8px"}
        bg={"#FDF6AD"}
        pb={5}
        flexDirection={"column"}
        flex={1}
      >
        <Flex
          bg={"#D2CFFC"}
          borderTopRadius={"8px"}
          justifyContent={"center"}
          alignItems={"center"}
          h={"56px"}
        >
          <Text textAlign={"center"} fontWeight={700} fontSize={"24px"}>
            <Trans
              i18nKey="subscribe.monthly.save"
              values={{ save_percent: displayContent.save_percent }}
            />
          </Text>
        </Flex>
        <Text
          fontWeight={700}
          fontSize={"16px"}
          textAlign={"center"}
          h={"35px"}
          lineHeight={"35px"}
        >
          {t("subscribe.premium")}
        </Text>
        <Text
          textAlign={"center"}
          h={"24px"}
          bg={"linear-gradient(90deg, #CBEE4C 29.63%, #D2CFFC 70.05%);"}
        >
          {displayContent.price}
        </Text>
        <Flex px={8} flexDirection={"column"} mt={3}>
          <Text fontSize="12px" fontWeight="bold" mb={1}>
            {t("subscribe.usage")}
          </Text>
          <List spacing={1} styleType="disc" pl={5} fontSize={"12px"}>
            <ListItem>
              {" "}
              <Trans
                i18nKey="subscribe.monthly.usage1"
                components={{ strong: <strong /> }}
              />
            </ListItem>
            <ListItem>
              <Trans
                i18nKey="subscribe.monthly.usage2"
                components={{ strong: <strong /> }}
              />
            </ListItem>
            <ListItem>
              <Trans
                i18nKey="subscribe.monthly.usage3"
                components={{ strong: <strong /> }}
              />
            </ListItem>
            {/* <ListItem>
              <Trans
                i18nKey="subscribe.monthly.usage4"
                components={{ strong: <strong /> }}
              />
            </ListItem> */}
          </List>
          <Text fontSize="12px" fontWeight="bold" my={1}>
            {t("subscribe.updates")}
          </Text>
          <List spacing={1} styleType="disc" pl={5} fontSize={"12px"}>
            <ListItem>{t("subscribe.monthly.updates.desc1")}</ListItem>
          </List>

          <Text fontSize="12px" fontWeight="bold" my={1}>
            {t("subscribe.ads")}
          </Text>
          <List spacing={1} styleType="disc" pl={5} fontSize="12px">
            <ListItem>
              <Trans
                i18nKey="subscribe.monthly.ads.desc1"
                components={{ strong: <strong /> }}
              />
            </ListItem>
          </List>
          <Center mt={5}>
            <Elements stripe={stripePromise}>
              <Button
                onClick={handleCheckout}
                _hover={{}}
                bg={
                  "linear-gradient(90deg, rgba(204, 239, 77, 0.75) 0%, rgba(211, 208, 253, 0.75) 100%);"
                }
                borderRadius={"16px"}
                w={"131px"}
                h={"29px"}
              >
                {t("subscribe.select")}
              </Button>
            </Elements>
          </Center>
        </Flex>
      </Flex>
    </>
  );
});
