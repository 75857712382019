import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Image,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import Coin from "assets/coin.svg";
import Doge from "assets/Graphic.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCheckSubscribeModal,
  openCheckPremiumModal,
  closeCheckPremiumModal,
  openInvitationModal,
  closeInvitationModal,
} from "RTK/Slice/modalSlice";
import CheckPremiumModal from "./CheckPremiumModal";
import InvitationModal from "../InvitationModal";

interface CheckSubscribeModalProps {
  open: boolean;
  setOpen: () => void;
}

const CheckSubscribeModal: FC<CheckSubscribeModalProps> = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const [displayMessage, setDisplayMessage] = useState(false);
  const { checkSubModal, checkPremModal, invitModal } = useSelector(
    (state: any) => state.modal
  );
  const dispatch = useDispatch();

  const modelSize = useBreakpointValue(
    {
      base: "full",
      xl: "lg",
    },
    {
      fallback: "md",
    }
  );

  return (
    <>
      <Modal isOpen={open} onClose={setOpen} size={modelSize}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "100%", xl: "600px" }}>
          <ModalHeader py={"20px"}>
            <Flex
              justifyContent="center"
              alignItems="center"
              mt={{ base: "20px", xl: "0" }}
            >
              <Image src={Coin} alt="coin" />
              <Text ml={2} fontSize="lg" fontWeight="bold">
                {t("Get Coins")}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              dispatch(closeCheckSubscribeModal());
              setDisplayMessage(false);
            }}
          />
          <ModalBody pb={"20px"}>
            <Box mb={4} pl={"20px"}>
              <Image src={Doge} alt="doge" width="100%" />
            </Box>
            <Flex alignItems="center" my={"20px"} pl={"20px"}>
              <Text ml={2}>
                {t("You already subscribed to the premium plan")}
              </Text>
            </Flex>

            <Flex direction="column" alignItems="center">
              <Flex
                mt={4}
                p={2}
                border="1px solid black"
                borderRadius="10px"
                bg="#D2CFFC"
                fontSize="lg"
                fontWeight="bold"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={() => {
                  dispatch(closeCheckSubscribeModal());
                  dispatch(openCheckPremiumModal());
                }}
                width="100%"
              >
                {t("Check subscription Status")}
              </Flex>

              <Flex justifyContent="center" width="100%" my={"10px"}>
                <Text>{t("Or")}</Text>
              </Flex>

              <Flex
                p={2}
                border="1px solid black"
                borderRadius="10px"
                bg="#D2CFFC"
                fontSize="lg"
                fontWeight="bold"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={() => {
                  dispatch(closeCheckSubscribeModal());
                  dispatch(openInvitationModal());
                }}
                width="100%"
              >
                {t("Earn extra credits by inviting your friends")}
              </Flex>
            </Flex>

            <Flex
              m={"20px"}
              justifyContent="center"
              alignItems="center"
              fontSize="lg"
              fontWeight="bold"
              visibility={displayMessage ? "visible" : "hidden"}
            >
              {t("ConfirmCopy")}
              <Image src={Coin} alt="coin" ml={2} />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal for Check Premium */}
      <CheckPremiumModal
        open={checkPremModal}
        onClose={() => dispatch(closeCheckPremiumModal())}
      />

      {/* Modal for Invitation */}
      <InvitationModal
        open={invitModal}
        onClose={() => dispatch(closeInvitationModal())}
      />
    </>
  );
};

export default CheckSubscribeModal;