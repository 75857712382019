import LeftNav from "Pages/LeftNav/LeftNav";
import ChatRoom from "Pages/Chat/ChatRoom/ChatRoom";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "./AuthFlow/Login";
import ForgetPassword from "Components/AuthFlow/ForgetPassword";
import CheckEmail from "Components/AuthFlow/CheckEmail";
import ResetPassword from "Components/AuthFlow/ResetPassword";
import ChatBot from "Chatbot/ChatBot";
import LoadingSpinner from "Components/Loading/LoadingSpinner";
import ShareSolution from "../Components/ShareSolution/ShareSolution";
import Sitemap from "./Sitemap";
import Header from "Components/Header";
import Close from "../assets/Close.svg";
import SignUp from "./AuthFlow/SignUp";
import SetPassword from "./AuthFlow/SetPassword";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { useMemo, useEffect, useState } from "react";
import ShareNav from "../Pages/LeftNav/ShareNav";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentChatId,
  updateToken,
  loginUser,
  loginEventTrigger,
} from "RTK/Slice/userSlice";
import { useUpdateShareMode } from "hooks/useUpdateShareMode";
import Redirect from "./Redirect";
import { getSubscriptionInfo } from "util/userRequest";
import Banner from "./banner";

const AppLayout = () => {
  const { currentChatId, breakpoint, user, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isShareMode } = useUpdateShareMode();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isPremiumUser = user?.user_info?.package !== "free";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (queryParams.get("access_token")) {
      const accessToken = queryParams.get("access_token") || "";
      const refreshToken = queryParams.get("refresh_token") || "";
      const expired_at = queryParams.get("expiured_at") || "";
      const expired_in = queryParams.get("expired_in");
      const token_type = queryParams.get("token_type");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      dispatch(
        updateToken({
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_in: expired_in,
          expires_at: expired_at,
          token_type,
        })
      );
      dispatch(
        loginUser({
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_in: expired_in,
          expires_at: expired_at,
          token_type,
        })
      );
      dispatch(loginEventTrigger(true));
    }
  }, [location]);
  const showHeader = useMemo(() => {
    return location.pathname === "/" || location.pathname.startsWith("/chat/");
  }, [location.pathname]);

  return (
    <Flex
      style={{ height: "100vh", width: "100%" }}
      flexDirection={{ base: "column", xl: "row" }}
      overflow={{ base: "hidden", xl: "auto" }}
    >
      {showHeader && (
        <Box hideFrom={"xl"}>
          <Header setCollapsed={onOpen} onClose={onClose} />
        </Box>
      )}

      <Box
        width={"30%"}
        h={"100%"}
        padding={"0px 20px"}
        hideBelow={"xl"}
        position={"relative"}
      >
        {isShareMode ? <ShareNav /> : <LeftNav onCloseDrawer={onClose} />}
      </Box>

      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        size={"full"}
        placement={"left"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton onClose={onClose} />
          <DrawerBody padding={"10px"}>
            {isShareMode ? <ShareNav /> : <LeftNav onCloseDrawer={onClose} />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #fff",
          height: "100vh",
        }}
        w={{ base: "100%", xl: "70%" }}
        padding={{ base: "0", xl: "20px" }}
      >
        {isAuthenticated && (
          <Banner isAuthenticated={isAuthenticated} isPremiumUser={isPremiumUser} />
        )}

        {currentChatId && !isShareMode && breakpoint === "xl" && (
          <Box
            hideBelow={"xl"}
            w={{ base: "100%", xl: "100%" }}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
              dispatch(setCurrentChatId(null));
            }}
          >
            <Image src={Close} />
          </Box>
        )}
        <Flex
          style={{
            backgroundColor: "white",
            height: "100%",
            width: "100%",
          }}
          padding={{ base: "0", xl: "20px" }}
        >
          <Routes>
            <Route path="/" element={<ChatRoom />} />
            <Route path="/chat/:chatId" element={<ChatBot />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/setPassword" element={<SetPassword />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/checkEmail" element={<CheckEmail />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/shared_chat/:Id" element={<ShareSolution />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/cancel" element={<Redirect />} />
            <Route path="/*" element={<LoadingSpinner />} />
          </Routes>
        </Flex>
      </Box>
    </Flex>
  );
};
export default AppLayout;
