import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Image,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import Coin from "assets/coin.svg";
import Send from "assets/Vector.svg";
import FreeCoin from "assets/FreeCoin.svg";
import Doge from "assets/Graphic.svg";
import { getToken } from "util/userRequest";
import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { closeInvitationModal } from "RTK/Slice/modalSlice";

interface InvitationModalProps {
  open: boolean;
  onClose: () => void;
}

const InvitationModal: FC<InvitationModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [displayMessage, setDisplayMessage] = useState(false);
  const { user } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { invitModal } = useSelector((state: any) => state.modal);

  const handleCopy = async () => {
    const token = await getToken(user?.user_info?.user_id);
    setDisplayMessage(true);
    copy(`${window.location.host}/?token=${token}`);
  };

  const modelSize = useBreakpointValue(
    {
      base: "full",
      xl: "lg",
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "md",
    }
  );

  return (
    <Modal
      isOpen={invitModal || open}
      onClose={
        onClose ? () => onClose() : () => dispatch(closeInvitationModal())
      }
      size={modelSize}
    >
      <ModalOverlay />
      <ModalContent maxW={{ base: "100%", xl: "600px" }}>
        <ModalHeader py={"20px"}>
          <Flex
            justifyContent="center"
            alignItems="center"
            mt={{ base: "20px", xl: "0" }}
          >
            <Image src={Coin} alt="coin" />
            <Text ml={2} fontSize="lg" fontWeight="bold">
              {t("InviteFriend")}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          onClick={() => {
            dispatch(closeInvitationModal());
            setDisplayMessage(false);
          }}
        />
        <ModalBody pb={"20px"}>
          <Box mb={4} pl={"20px"}>
            <Image src={Doge} alt="doge" width="100%" />
          </Box>
          <Flex alignItems="center" my={"20px"} pl={"20px"}>
            <Box
              border="1px solid black"
              height="24px"
              width="24px"
              borderRadius="4px"
              bg="#CBEE4C"
              p={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={Send} alt="send" />
            </Box>
            <Text ml={2}>{t("InviteYourFriend")}</Text>
          </Flex>
          <Flex alignItems="center" my={"20px"} pl={"20px"}>
            <Box
              border="1px solid black"
              height="24px"
              width="24px"
              borderRadius="4px"
              bg="#CBEE4C"
              p={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={FreeCoin} alt="freecoin" />
            </Box>
            <Text ml={2}>{t("FreeCoins")}</Text>
          </Flex>
          {/* <Link href="#" textDecoration="underline" display="flex" justifyContent="center" pl={'20px'}>
            {t("TermsCondition")}
          </Link> */}
          <Flex
            mt={4}
            p={2}
            border="1px solid black"
            borderRadius="10px"
            bg="#D2CFFC"
            fontSize="lg"
            fontWeight="bold"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={handleCopy}
            ml={"20px"}
          >
            {t("StartEarning")}
          </Flex>
          <Flex
            m={"20px"}
            justifyContent="center"
            alignItems="center"
            fontSize="lg"
            fontWeight="bold"
            visibility={displayMessage ? "visible" : "hidden"}
          >
            {t("ConfirmCopy")}
            <Image src={Coin} alt="coin" ml={2} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InvitationModal;
