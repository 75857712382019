import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  Text,
  InputLeftElement,
  Spinner,
  VStack,
  Image,
} from "@chakra-ui/react";
import PasswordModal from "./PasswordModal";
import { useState } from "react";
import { EmailIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { t } from "i18next";
import GoogleIcon from "assets/Google icon.svg";
import AppleIcon from "assets/Apple.svg";
import { useSelector } from "react-redux";
import { request } from "util/request";
import { redirectToProvider } from "util/util";

const SignUp = () => {
  const { invitation_token, platform_token, breakpoint } = useSelector(
    (state: any) => state.user
  );

  const [loading, setLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [input, setInput] = useState("");

  const handleOAuthLogin = async (provider) => {
    try {
      setLoading(true);
      redirectToProvider(provider);
      localStorage.setItem("Oauth", "true");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const checkInputValue = () => {
    if (input === "") {
      setDisplayMessage("email cannot be empty");
      return false;
    }
    return true;
  };

  const handleRegister = async () => {
    if (!checkInputValue()) {
      return;
    }
    setLoading(true);
    const result = await request("post", "invite_user_by_email", {
      email: input,
      redirect_to_url: `${window.location.origin}/setPassword`,
      invitation_token,
      platform_token,
    })
      .catch((res) => {
        if (res.response.status === 409) {
          setDisplayMessage("email is already exist on the database");
          setLoading(false);
        } else {
          setDisplayMessage("something goes wrong");
          setLoading(false);
        }
      })
      .then((data) => {
        setLoading(false);
        return data;
      });
    setLoading(false);
  };

  return (
    <Box h={"100%"} width={"100%"}>
      <PasswordModal>
        {loading && <Spinner />}
        <VStack spacing={4} align="stretch" width={{ base: "90%", xl: "70%" }}>
          <FormControl isInvalid={!!displayMessage}>
            <VStack spacing={4} align="stretch">
              <Flex direction={"row"} alignItems={"center"} gap={"10px"}>
                <Text
                  fontSize="2rem"
                  textAlign={{ base: "center", xl: "left" }}
                  fontWeight={700}
                >
                  {t("SignUp")}
                </Text>
                <Text
                  fontSize="1rem"
                  textAlign={{ base: "center", xl: "left" }}
                  fontWeight={400}
                  color={"red"}
                >
                  {displayMessage}
                </Text>
              </Flex>

              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="black" />
                </InputLeftElement>
                <Input
                  type="email"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Email"
                  background={"#d0f979"}
                  required={true}
                />
              </InputGroup>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  backgroundColor={"#d2cffc"}
                  onClick={handleRegister}
                  h={"60px"}
                  width={"30%"}
                  border={"1px solid #000"}
                  fontWeight={800}
                >
                  {t("SignUp")}
                </Button>
              </Box>
            </VStack>
          </FormControl>

          <Flex justify="flex-start" width="100%">
            <Flex direction={"row"}>
              <Text>{t("AlreadyHaveAccount")}</Text>
              <Text
                onClick={handleRegister}
                cursor="pointer"
                marginLeft={"5px"}
              >
                <Link to={"/login"}>
                  <Text color={"#A020F0"}>{t("Login")}</Text>
                </Link>
              </Text>
            </Flex>
          </Flex>

          <Flex align="center" width="100%">
            <Box flex="1" height="1px" bgColor="black"></Box>
            <Text mx={20} fontWeight={800}>
              {t("Or")}
            </Text>
            <Box flex="1" height="1px" bgColor="black"></Box>
          </Flex>
          <Flex direction={breakpoint === "xl" ? "row" : "column"} gap={"10%"}>
            <Button
              border={"1px solid #000"}
              leftIcon={<Image src={GoogleIcon} />}
              backgroundColor={"#d2cffc"}
              onClick={() => handleOAuthLogin("google")}
              h={"60px"}
              flex={1}
              fontWeight={600}
              style={{ padding: breakpoint === "xl" ? 0 : "10px" }}
            >
              {t("Google")}
            </Button>
            <Button
              border={"1px solid #000"}
              backgroundColor={"#d2cffc"}
              leftIcon={<Image src={AppleIcon} />}
              onClick={() => handleOAuthLogin("apple")}
              h={"60px"}
              flex={1}
              fontWeight={600}
              style={{ padding: breakpoint === "xl" ? 0 : "10px" }}
            >
              {t("Apple")}
            </Button>
          </Flex>
        </VStack>
      </PasswordModal>
    </Box>
  );
};

export default SignUp;
