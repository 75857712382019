import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Spinner,
  Image,
  useDisclosure,
  Text,
  Grid,
} from "@chakra-ui/react";
import QuestionContainer from "Components/QuestionContainer/QuestionContainer";
import Examples from "assets/Examples.json";
import { useTranslation } from "react-i18next";
import Language from "assets/Language.svg";
import JoinDiscord from "assets/JoinDiscord.svg";
import Coins from "assets/coin.svg";
import Logout from "assets/Logout.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  logoutUser,
  setInvitationToken,
  setPlatformToken,
  updateSubscription,
} from "RTK/Slice/userSlice";
import {
  openCheckSubscribeModal,
  closeCheckSubscribeModal,
  handleTokenModal,
} from "RTK/Slice/modalSlice";
import { useChatHistory } from "hooks/useChatHistory";
import LanguageSelectModal from "Components/Modal/LanguageSelectModal";
import UserInfo from "./components/UserInfo";
import { getSubscriptionInfo, signout } from "util/userRequest";
import TokenOutModal from "Components/Modal/TokenOutModal";
import CheckSubscribeModal from "Components/Modal/UnsubscribeModal/CheckSubscribeModal";

const LeftNav = ({ onCloseDrawer }) => {
  const {
    isAuthenticated,
    user,
    chatHistory,
    temp_credit,
    perm_credit,
    breakpoint,
  } = useSelector((state: any) => state.user);
  const { checkSubModal, tokenModal } = useSelector(
    (state: any) => state.modal
  );
  const { getQuestions } = useChatHistory();
  const dispatch = useDispatch();
  // const [openModal, setOpenModal] = useState(false);
  const [example, setExample] = useState();
  const [loading, setLoading] = useState(false);
  const [isFreeUser, setIsFreeUser] = useState(
    user?.user_info?.package === "free"
  );
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogout = () => {
    signout().catch((error) => console.error("Signout error:", error));
    dispatch(logoutUser());
    dispatch(setInvitationToken(""));
    dispatch(setPlatformToken(""));
    navigate("/login");
  };

  const handleNavigation = () => {
    navigate("/login");
    onCloseDrawer && onCloseDrawer();
  };

  useEffect(() => {
    if (tokenModal && breakpoint === "mobile") {
      onCloseDrawer && onCloseDrawer();
    }
  }, [tokenModal]);

  const getExampleList = async () => {
    setLoading(true);
    const data = Examples.data.data;
    setLoading(false);
    setExample(data as any);
  };

  const subscription = async () => {
    const { result } = await getSubscriptionInfo();
    dispatch(updateSubscription(result));
    setIsFreeUser(!result);
  };

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated) {
      getQuestions();
      subscription();
    } else {
      getExampleList();
    }
    setLoading(false);
  }, [isAuthenticated, language, temp_credit, perm_credit, isFreeUser]);

  return (
    <Flex
      direction="column"
      width="100%"
      height={breakpoint === "xl" ? "100%" : isAuthenticated ? "95%" : "90%"}
      overflow="hidden"
      px={{ base: 0, xl: "20px" }}
      pt={"10px"}
    >
      <Box>
        <Text as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          {isAuthenticated ? t("History") : t("Example")}
        </Text>
      </Box>

      <Box
        flex="1"
        overflowY="auto"
        bg="white"
        overflowX={"hidden"}
        mt={"20px"}
      >
        {loading ? (
          <Spinner />
        ) : (
          <QuestionContainer
            groupChatHistory={isAuthenticated ? chatHistory : example}
            onCloseDrawer={onCloseDrawer}
          />
        )}
      </Box>

      <Box py={"20px"} pb={isAuthenticated ? "20px" : "90px"}>
        {isAuthenticated ? (
          <>
            <UserInfo
              user={user}
              isFreeUser={isFreeUser}
              perm_credit={perm_credit}
              temp_credit={temp_credit}
            />
            <Grid
              gap="10px"
              gridTemplateColumns={{ base: "1fr 1fr 1fr 1fr", xl: "1fr 1fr" }}
              h={{ base: "62px", xl: "100px" }}
            >
              <Flex
                as={Link}
                to="https://discord.gg/Gn4EZJwAaH"
                fontWeight={500}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
                w={"90%"}
                h={"90%"}
                gap={"5px"}
                flexDirection={{ base: "column", xl: "row" }}
                width="100%"
                bg={"#d2cffc"}
                borderRadius={"10px"}
                border={"1px solid black"}
                fontSize={"10px"}
              >
                <Image src={JoinDiscord} height="20px" />
                {t("JoinDiscord")}
              </Flex>

              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
                w={"90%"}
                h={"90%"}
                gap={"5px"}
                onClick={() =>
                  isFreeUser
                    ? dispatch(handleTokenModal(true))
                    : dispatch(openCheckSubscribeModal())
                }
                fontWeight={500}
                flexDirection={{ base: "column", xl: "row" }}
                width="100%"
                bg={"#d2cffc"}
                borderRadius={"10px"}
                border={"1px solid black"}
                fontSize={"10px"}
                cursor={"pointer"}
              >
                <Image src={Coins} height="20px" />
                {t("GetCoins")}
              </Flex>

              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
                w={"90%"}
                h={"90%"}
                gap={"5px"}
                onClick={onOpen}
                fontWeight={500}
                width="100%"
                bg={"#d2cffc"}
                flexDirection={{ base: "column", xl: "row" }}
                borderRadius={"10px"}
                border={"1px solid black"}
                fontSize={"10px"}
                cursor={"pointer"}
              >
                <Image src={Language} height="20px" />
                {t("Language")}
              </Flex>

              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
                w={"90%"}
                h={"90%"}
                gap={"5px"}
                onClick={handleLogout}
                fontWeight={500}
                width="100%"
                flexDirection={{ base: "column", xl: "row" }}
                borderRadius={"10px"}
                border={"1px solid black"}
                fontSize={"10px"}
                cursor={"pointer"}
              >
                <Image src={Logout} height="20px" />
                {t("Logout")}
              </Flex>
            </Grid>
          </>
        ) : (
          <Grid gap="10px" h={{ base: "62px", xl: "100px" }}>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              w={"90%"}
              h={{ base: "40px" }}
              gap={"15px"}
              onClick={onOpen}
              fontWeight={500}
              width="100%"
              bg={"#d2cffc"}
              borderRadius={"10px"}
              border={"1px solid black"}
              fontSize={"10px"}
              cursor={"pointer"}
            >
              <Image src={Language} height="20px" />
              {t("Language")}
            </Flex>

            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              w={"90%"}
              h={{ base: "40px" }}
              gap={"15px"}
              as={Link}
              fontWeight={500}
              to="https://discord.gg/Gn4EZJwAaH"
              width="100%"
              bg={"#d2cffc"}
              borderRadius={"10px"}
              border={"1px solid black"}
              fontSize={"10px"}
            >
              <Image src={JoinDiscord} height="20px" />
              {t("JoinDiscord")}
            </Flex>

            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              w={"90%"}
              h={{ base: "40px" }}
              gap={"5px"}
              onClick={handleNavigation}
              width="100%"
              bg={"#d2cffc"}
              fontWeight={500}
              borderRadius={"10px"}
              border={"1px solid black"}
              fontSize={"10px"}
              cursor={"pointer"}
            >
              <strong>{t("SignIn/SignUp")}</strong>
            </Flex>
          </Grid>
        )}
      </Box>
      <TokenOutModal shouldClose={true} />
      <LanguageSelectModal isOpen={isOpen} onClose={onClose} />
      <CheckSubscribeModal
        open={checkSubModal}
        setOpen={() => {
          dispatch(closeCheckSubscribeModal());
        }}
      />
    </Flex>
  );
};

export default LeftNav;
