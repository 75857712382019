export function redirectToProvider(provider, invitationToken, platformToken) {
  const frontend_url = `${window.location.origin}`;
  const redirect_uri = `${process.env.REACT_APP_BASE_URL}/login/callback`; // 后端处理的URL
  const customParam = {
    provider: provider,
    redirect_url: frontend_url,
    invitation_token: invitationToken,
    platform_token: platformToken,
  };
  const state = JSON.stringify(customParam); // 防止 CSRF 攻击的随机字符串
  if (provider === "google") {
    const clientId =
      "315924983429-flvic0kef0s19nv9qngdnu7mk1vi3j01.apps.googleusercontent.com";
    const responseType = "code";
    const scope = "profile email";
    const access_type = "offline";
    const prompt = "consent";
    const authUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=${responseType}&scope=${scope}&access_type=${access_type}&prompt=${prompt}&state=${encodeURIComponent(
      state
    )}`;
    window.location.href = authUrl;
  } else if (provider === "apple") {
    const clientId = "com.mathsolver.top.oauth";
    const scope = "name email";
    const authUrl = `https://appleid.apple.com/auth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&scope=${scope}&state=${encodeURIComponent(
      state
    )}&response_mode=form_post`;
    window.location.href = authUrl;
  }
}
