import { createSlice } from "@reduxjs/toolkit";

// Initial state template
const initialState = {
  user: {},
  chatHistory: [],
  perm_credit: 0,
  temp_credit: 0,
  access_token: "",
  refresh_token: "",
  expires_in: 0,
  expires_at: 0,
  token_type: "",
  isAuthenticated: false, // Indicates whether the user is authorized
  currentChatId: "", //currentPage
  currentMode: "",
  breakpoint: "",
  invitation_token: "",
  invitation_list: [],
  platform_token: "",
  loginEvent: false,
};

// Slice for user state
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    //log out user and deauthorize
    reset: (state) => {
      return initialState;
    },
    logoutUser: (state) => {
      state.user = {};
      state.access_token = "";
      state.chatHistory = [];
      state.isAuthenticated = false; // Reset authorization on logout
    },
    updateToken: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.expires_at = action.payload.expires_at;
      state.expires_in = action.payload.expires_in;
    },
    loginEventTrigger: (state, action) => {
      state.loginEvent = action.payload;
    },
    updateUserProfiles: (state, action) => {
      state.user.user_info = action.payload;
    },
    //login service
    loginUser: (state, action) => {
      state.user = action.payload;
      state.access_token = action.payload.access_token;
      state.expires_at = action.payload.expires_at;
      state.expires_in = action.payload.expires_in;
      state.token_type = action.payload.token_type;
      state.refresh_token = action.payload.refresh_token;
      state.isAuthenticated = true;
    },
    setCurrentChatId: (state, action) => {
      state.currentChatId = action.payload;
    },
    setChatHistory: (state, action) => {
      state.chatHistory = action.payload;
    },
    setCredit: (state, action) => {
      state.perm_credit = action.payload.perm_credit;
      state.temp_credit = action.payload.temp_credit;
    },
    setCurrentMode: (state, action) => {
      state.currentMode = action.payload;
    },
    updateSubscription: (state, action) => {
      if (state.user.user_info) {
        state.user.user_info.package = action.payload ? "premium" : "free";
      } else {
        state.user = {
          ...state.user,
          user_info: { package: action.payload ? "premium" : "free" },
        };
      }
    },
    setBreakPoint: (state, action) => {
      state.breakpoint = action.payload;
    },
    setInvitationToken: (state, action) => {
      state.invitation_token = action.payload;
    },
    setPlatformToken: (state, action) => {
      state.platform_token = action.payload;
    },
    deleteChatById: (state, action) => {
      const updatedChatHistory = { ...state.chatHistory };
      for (const chatId in updatedChatHistory) {
        updatedChatHistory[chatId] = updatedChatHistory[chatId].filter(
          (item) => item.id !== action.payload
        );
        if (updatedChatHistory[chatId].length === 0) {
          delete updatedChatHistory[chatId];
        }
      }
      state.chatHistory = updatedChatHistory;
    },
  },
});

export const {
  logoutUser,
  loginUser,
  setCurrentChatId,
  setChatHistory,
  setCredit,
  setCurrentMode,
  setBreakPoint,
  deleteChatById,
  setInvitationToken,
  setPlatformToken,
  updateToken,
  loginEventTrigger,
  updateUserProfiles,
  updateSubscription,
  reset,
} = userSlice.actions;
export default userSlice.reducer;
