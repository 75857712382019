import React, { FC, memo, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Helper from "assets/this-is-fine.svg";
import Learner from "assets/computer-guy-meme.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChatId, setCurrentMode } from "RTK/Slice/userSlice";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import "katex/dist/katex.min.css";
import Trash from "../../assets/Trash.svg";
import Close from "../../assets/Close.svg";
import "./question.css";
import { deleteChat } from "util/userRequest";
import { deleteChatById } from "RTK/Slice/userSlice";
import { setStatus } from "RTK/Slice/fileSlice";
import { current } from "@reduxjs/toolkit";

interface QuestionItem {
  id: string;
  question: string;
  learner_mode: string;
  thumbnail_str: string;
  onCloseDrawer?: () => void;
}

interface QuestionListProps {
  questions: QuestionItem[];
  onCloseDrawer?: () => void;
}

const QuestionItem: FC<QuestionItem> = memo(
  ({ id, question, learner_mode, thumbnail_str, onCloseDrawer }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, currentChatId } = useSelector(
      (state: any) => state.user
    );
    const [confirmDelete, setConfirmDelete] = useState(false);
    const TagIcon = (mode) => {
      return mode ? (
        <img style={{ width: "35px", height: "35px" }} src={Learner} />
      ) : (
        <img style={{ width: "35px", height: "35px" }} src={Helper} />
      );
    };

    const handleDelete = (e) => {
      e.stopPropagation();
      setConfirmDelete(true);
    };

    const cancleDelete = (e) => {
      e.stopPropagation();
      setConfirmDelete(false);
    };

    const onDelete = (e) => {
      e.stopPropagation();
      deleteChat(id);
      if (id === currentChatId) {
        navigate("/");
      }
      setConfirmDelete(false);
      dispatch(deleteChatById(id));
      dispatch(setCurrentChatId(null));
    };
    const imageUrl = thumbnail_str
      ? `data:image/png;base64,${thumbnail_str}`
      : null;
    return (
      <>
        <div
          style={{
            cursor: "pointer",
            border: "1px solid black",
            backgroundColor: "#D0F979",
            borderRadius: "10px",
            padding: "10px",
          }}
          onClick={(e) => {
            const mode = learner_mode ? "learner" : "helper";
            dispatch(setCurrentMode(mode));
            dispatch(setCurrentChatId(id));
            dispatch(setStatus(false));
            navigate(`/chat/${id}`);
            onCloseDrawer && onCloseDrawer();
          }}
        >
          <Row style={{ width: "100%", height: "40px" }}>
            {!confirmDelete && (
              <Col
                span={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                {TagIcon(learner_mode)}
              </Col>
            )}
            <Col span={20}>
              {confirmDelete ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                  onClick={(e) => onDelete(e)}
                >
                  <div
                    style={{
                      flex: '1',
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <span style={{ flex: "1" }}>Confirm to delete</span>
                    </div>
                    <div onClick={(e) => cancleDelete(e)}>
                      <img src={Close} alt={"close"} />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    textOverflow: "ellipsis",
                    height: "40px",
                    lineHeight: "40px",
                    textAlign: "center",
                    overflow: "hidden",
                    fontWeight: "800",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: `${
                        imageUrl ? "space-around" : "space-between"
                      } `,
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {imageUrl ? (
                      <>
                        <img
                          src={imageUrl}
                          alt="Thumbnail"
                          style={{
                            margin: "0 10px",
                            height: "40px",
                            width: "40px",
                          }}
                        />
                        <span
                          style={{
                            flex: "1",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <ReactMarkdown
                            children={question}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex, rehypeRaw]}
                            className={"reactMarkDown"}
                          />
                        </span>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            visibility: "hidden",
                            margin: "0 10px",
                            height: "40px",
                            width: "40px",
                          }}
                        ></div>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            flex: "1",
                          }}
                        >
                          <ReactMarkdown
                            children={question}
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex, rehypeRaw]}
                            className={"reactMarkDown"}
                          />
                        </span>
                      </div>
                    )}

                    {isAuthenticated && (
                      <img
                        src={Trash}
                        alt={"trash"}
                        onClick={(e) => handleDelete(e)}
                      />
                    )}
                  </div>{" "}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
);

export const QuestionList: FC<QuestionListProps> = memo(
  ({ questions, onCloseDrawer }) => {
    return (
      <>
        {questions.map(({ id, question, learner_mode, thumbnail_str }) => (
          <QuestionItem
            key={id}
            question={question}
            id={id}
            learner_mode={learner_mode}
            thumbnail_str={thumbnail_str}
            onCloseDrawer={onCloseDrawer}
          />
        ))}
      </>
    );
  }
);
