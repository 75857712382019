import { useMemo, useState } from "react";
import {
  Box,
  Flex,
  Avatar,
  Image,
  Button,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Modal } from "antd";
import Logo from "assets/small-logo.svg";
import Typewriter from "Chatbot/TypeWriter";
import Grumpy from "assets/grumpy.svg";
import { getSharedId } from "util/userRequest";
import "./chatitem.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Space } from "antd";
import PreviewImage from "Components/PreviewImage";

interface ChatItemProps {
  role: string;
  message: string;
  scrollRef: any;
  lastMessage: boolean;
  imgSrc: string;
  defaultRole?: string;
  username?: string;
  isAuthenticated?: boolean;
}

export default function ChatItem({
  role,
  message,
  scrollRef,
  lastMessage,
  imgSrc,
  defaultRole = "AI",
  isAuthenticated,
  username,
}: ChatItemProps): JSX.Element {
  const { user, currentChatId, breakpoint } = useSelector(
    (state: any) => state.user
  );
  const [shareId, setShareId] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const toast = useToast({
    position: "top",
    status: "success",
    duration: 8000,
  });
  const isShowAnimation = useMemo(() => {
    return !imgSrc && (!message || message.length === 0);
  }, [imgSrc, message]);

  const backgroundColor = useMemo(() => {
    if (role === "AI") {
      return "#d2cffc";
    } else if (imgSrc && message) {
      return "#d0f979";
    } else if (imgSrc) {
      return "transparent";
    } else {
      return "#d0f979";
    }
  }, [role, imgSrc, message]);

  const handleCopy = (sharedId) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/shared_chat/${sharedId}`
    );
    toast({
      description: " You can share the link",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    setOpen(false);
  };
  return (
    <Flex
      w="100%"
      pt="20px"
      justifyContent={role === defaultRole ? "flex-start" : "flex-end"}
      px={{ base: "10px", xl: 0 }}
    >
      <Flex
        order={role === defaultRole ? 1 : 2}
        position="relative"
        margin="30px 10px"
        top="-50%"
      >
        <Avatar
          bg={role === defaultRole ? "" : "#ef6c00"}
          borderRadius={role === defaultRole ? "0" : "full"}
          src={
            role === defaultRole
              ? Logo
              : user?.avatar_url || null
          }
          w={{ base: "38px", xl: "40px" }}
          h={{ base: "38px", xl: "40px" }}
        >
          {/*{role === defaultRole ? "" : username} */}
        </Avatar>
      </Flex>
      <Flex
        order={1}
        mt="4%"
        justifyContent={role === defaultRole ? "flex-start" : "flex-end"}
        flex="1"
        overflow="hidden"
        w="100%"
      >
        <VStack w="max-content" align="flex-start">
          <Flex
            w={defaultRole === role ? "100%" : "max-content"}
            bg={backgroundColor}
            borderRadius="10px"
            mt="3px"
            p="10px"
            lineHeight="1.6"
            flexDirection="column"
            alignSelf={defaultRole === role ? "flex-start" : "flex-end"}
            maxWidth={{ base: "20rem", xl: "32rem" }}
          >
            {imgSrc && (
              <PreviewImage
                src={`data:image/png;base64,${imgSrc}`}
                mb={message ? "10px" : "0"}
                objectFit="contain"
              />
            )}
            {message && (
              <Typewriter scrollRef={scrollRef} text={message} speed={0} />
            )}
            {isShowAnimation && (
              <Flex
                className="green-circle"
                h="100%"
                w="100%"
                minH="150px"
                minW="150px"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  border="6px solid #A8D940"
                  borderRadius="50%"
                  w="107px"
                  h="107px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    className="yellow-circle"
                    border="6px solid #D9E94D"
                    borderRadius="50%"
                    w="80px"
                    h="80px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={Grumpy}
                      className="moveToCorner"
                      minW="40px"
                      minH="40px"
                    />
                  </Box>
                </Box>
              </Flex>
            )}
          </Flex>

          {defaultRole === "AI" && (
            <Row
              style={{
                display: role === defaultRole && lastMessage ? "" : "none",
                marginTop: "6px",
                marginBottom: `${breakpoint === "xl" ? "40px" : "100px"}`,
              }}
            >
              {!(!message || message.length === 0) && (
                <Space style={{ marginBottom: "5%" }}>
                  <Button
                    style={{ backgroundColor: "#d0f979" }}
                    onClick={() => {
                      navigator.clipboard.writeText(message);
                      toast({
                        description: "Content Copied",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }}
                  >
                    {t("CopyLaTex")}
                  </Button>
                  {isAuthenticated && (
                    <Button
                      style={{
                        backgroundColor: "#d0f979",
                      }}
                      onClick={async () => {
                        const shared_id = await getSharedId({
                          chat_id: currentChatId,
                          is_permanent: true,
                        });
                        setShareId(shared_id);
                        if (breakpoint !== "xl") {
                          setOpen(true);
                        } else {
                          handleCopy(shared_id);
                        }
                      }}
                    >
                      {t("ShareChat")}
                    </Button>
                  )}
                  <Modal
                    open={open}
                    onCancel={() => setOpen(false)}
                    width={"100%"}
                    footer={null}
                  >
                    <Box>{t("ConfirmCopyUrl")}</Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpen(false)}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCopy(shareId)}
                      >
                        {t("Confirm")}
                      </Button>
                    </Box>
                  </Modal>
                </Space>
              )}
            </Row>
          )}
        </VStack>
      </Flex>
    </Flex>
  );
}
