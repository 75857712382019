import React, { FC, useState } from "react";
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Flex,
  Text,
  ImageProps
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";

const PreviewImage: FC<ImageProps> = ({ src, alt, ...imageProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);
  const [scale, setScale] = useState(1.5);

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.deltaY > 0) {
      setScale(scale => Math.max(0.2, scale - 0.1));
    } else {
      setScale(scale => Math.min(3, scale + 0.1));
    }
  };

  return (
    <>
      <Box
        position="relative"
        display="inline-block"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onOpen}
        cursor="pointer"
      >
        <Flex
          background={"rgba(0, 0, 0, 0.5)"}
          width="100%"
          h={"100%"}
          opacity={isHovered ? 1 : 0}
          position={"absolute"}
          alignItems={"center"}
          justifyContent={"center"}
          transition={"opacity 0.3s"}
        >
          <ViewIcon color={"white"} />
          <Text color={"white"} ml={"2px"} fontSize={"12px"}>
            preview
          </Text>
        </Flex>
        <Image src={src} alt={alt} {...imageProps} />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0} position="relative" onWheel={handleScroll}>
            <Image
              src={src}
              alt={alt}
              w="100%"
              h="auto"
              style={{
                transform: `scale(${scale})`,
                transition: "transform 0.3s ease-in-out"
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreviewImage;
