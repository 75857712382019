import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import "katex/dist/katex.min.css"; // 引入 KaTeX 的样式
import style from "./markdown-styles.module.css";
import { useSelector } from "react-redux";

export default function Typewriter({ text, speed, scrollRef }) {
  const { breakpoint } = useSelector((state) => state.user);
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [displayedText]);

  useEffect(() => {
    let interval = null;
    if (speed > 0) {
      let currentIndex = 0;
      interval = setInterval(() => {
        if (currentIndex < text.length - 1) {
          setDisplayedText((prevText) => {
            if (currentIndex === 0) {
              return text[0] + text[1];
            } else {
              return prevText + text[currentIndex];
            }
          });
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, speed);
    } else {
      setDisplayedText(text);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [text, speed]);

  return (
    <ReactMarkdown
      urlTransform={(value) => value}
      children={displayedText}
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex, rehypeRaw]}
      className={
        breakpoint === "xl" ? style.reactMarkDown : style.reactMarkDownMobile
      }
      components={{
        span: ({ node, className, ...props }) => {
          return <span style={{ whiteSpace: className === "katex" ? "normal" : null }} {...props} className={className} />;
        }
      }}
    />
  );
}
