import { useCallback, useEffect, useState } from "react";
import { getInvitationList } from "../../util/userRequest";
import { useSelector } from "react-redux";

const useReferee = (userId) => {
  const { isAuthenticated } = useSelector((state: any) => state.user);
  const [referee, setReferee] = useState<string[]>([]);
  const [bonus, setBonus] = useState(0);
  const [open, setOpen] = useState(false);

  const fetchRefereeList = useCallback(async () => {
    let emailArray: string[] = [];
    let totalBonus: number = 0;
    const refereeList = await getInvitationList(userId);
    refereeList
      ?.filter((referee) => !referee.isNotify)
      ?.forEach((referee) => {
        emailArray.push(referee.guest_email);
        totalBonus += referee.bonus;
      });

    if (emailArray.length > 0) {
      setOpen(true);
      setReferee(emailArray);
      setBonus(totalBonus);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchRefereeList();
      // 设置定时器，每隔 5 分钟轮询一次
      const intervalId = setInterval(fetchRefereeList, 60 * 1000); // 1min
      // 清除定时器
      return () => clearInterval(intervalId);
    }
  }, []);

  return { referee, bonus, open, setOpen };
};

export default useReferee;
