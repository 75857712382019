import { useEffect, useState, useRef } from "react";
import { fetchTextStream, fetchNewChatStream } from "util/request";
import {
  Input,
  Flex,
  Box,
  Button,
  useToast,
  Spinner,
  Image,
} from "@chakra-ui/react";
import ChatItem from "Components/ChatItem/ChatItem";
import Helper from "assets/this-is-fine.svg";
import Learner from "assets/computer-guy-meme.svg";
import Send from "assets/purple-send.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserCredit } from "util/userRequest";
import { setCredit, setCurrentChatId } from "RTK/Slice/userSlice";
import { useChatHistory } from "hooks/useChatHistory";
import { handleTokenModal } from "RTK/Slice/modalSlice";

const TempChatBot = ({
  image_file = null,
  pastedImage = null,
  prompt = "",
  mode = "learner",
  language = "en",
  setInProgress,
  isMobile,
  setOpenTokenModal,
  setOpenErrorModal,
}) => {
  const { user, isAuthenticated, currentChatId } = useSelector(
    (state: any) => state.user
  );
  const dispatch = useDispatch();
  const { getQuestions } = useChatHistory();
  const navigate = useNavigate();
  const [chatBotMessages, setChatBotMessages] = useState<any>([
    { content: prompt, imgSrc: pastedImage, role: "user" },
  ]);
  const [keepChat, setKeepChat] = useState(true);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false); //等待结果返回完全
  const [loading, setLoading] = useState(true); //页面加载
  const [ccId, setCCID] = useState(); //页面加载
  const scrollRef = useRef(null);
  const { t } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    setLoading(false);
    handGetAnswer();
  }, []);

  useEffect(() => {
    if (scrollRef?.current) {
      (scrollRef.current as any).scrollTop = (
        scrollRef.current as any
      ).scrollHeight;
    }
  }, [chatBotMessages]);

  const refreshCredit = async () => {
    if (user?.user_info?.user_id) {
      const result = await getUserCredit();
      dispatch(setCredit(result?.data));
    }
  };

  const handleChatId = (chatId) => {
    window.history.pushState(null, "", "chat/" + chatId);
    setCCID(chatId);
    dispatch(setCurrentChatId(chatId));
  };

  //进来获取第一次提交问题的答案
  const handGetAnswer = async () => {
    try {
      const form = new FormData();
      if (image_file) {
        form.append("image_file", image_file);
      }
      form.append("mode", mode);
      form.append("prompt", prompt);
      form.append("language", language);
      prepareAIResponse();
      await fetchNewChatStream(
        "new_chat",
        handleChatId,
        handleAIResponse,
        handDoneCallBack,
        setInProgress,
        setOpenTokenModal,
        setOpenErrorModal,
        form,
        { method: "POST" }
      );
    } catch (error) {
      console.error("Error in handGetAnswer:", error);
    }
  };
  // 对话请求
  const handleSubmit = async (text) => {
    if (isTyping) {
      toast({
        title: "AI is answering.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    //更新question记录
    prepareAIResponse();
    await fetchTextStream(
      "chat",
      handleAIResponse,
      setKeepChat,
      handDoneCallBack,
      setOpenTokenModal,
      setOpenErrorModal,
      { chat_id: ccId, query: text, language: language },
      { method: "POST" }
    );
  };

  //预处理等待AI回复
  const prepareAIResponse = () => {
    if (isTyping) return;
    setIsTyping(true);
    setChatBotMessages((prevMessages) => [
      ...prevMessages,
      { content: "", role: "AI" },
    ]);
  };

  //接收AI返回的结果
  const handleAIResponse = (response) => {
    setChatBotMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      if (lastMessage && lastMessage.role === "AI") {
        const updatedLastMessage = {
          ...lastMessage,
          content: lastMessage.content + response,
        };
        return [
          ...prevMessages.slice(0, prevMessages.length - 1),
          updatedLastMessage,
        ];
      } else {
        return [...prevMessages, { content: response, role: "AI" }];
      }
    });
  };

  //文本流接收完的回调
  const handDoneCallBack = async (state) => {
    setIsTyping(!state);
    refreshCredit();
    getQuestions();
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter" && !e.shiftKey && input.trim()) {
      if (!isAuthenticated) {
        navigate(`/login`, { replace: true });
        return;
      }
      if (isTyping) {
        toast({
          title: "Waiting AI...",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setChatBotMessages([
        ...chatBotMessages,
        { content: input, role: "user" },
      ]);
      handleSubmit(input);
      setInput("");
    }
  };

  // 获取用户名首字母
  const getUsernameLetter = () => {
    const username = user?.email || "U";
    return username.charAt(0).toUpperCase();
  };

  return (
    <Box
      bg="#feeede"
      height="100%"
      width="100%"
      borderRadius={isMobile ? "0px" : "20px"}
      position="relative"
    >
      {loading ? (
        <Flex justify="center" align="center" height="100%">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          height="100%"
          width="100%"
          borderRadius={isMobile ? "0px" : "20px"}
          overflow="auto"
          ref={scrollRef}
        >
          <Box
            flex="1 1 0"
            overflow={{ base: "scroll", xl: "unset" }}
            mt={4}
            ref={scrollRef}
          >
            {chatBotMessages.map((message, index) => (
              <ChatItem
                key={index}
                role={message.role}
                message={message.content}
                imgSrc={message.imgSrc}
                username={getUsernameLetter()}
                scrollRef={scrollRef}
                lastMessage={index === chatBotMessages.length - 1}
                isAuthenticated={isAuthenticated}
              />
            ))}
          </Box>
          <Flex
            w={"100%"}
            justifyContent="center"
            alignItems="center"
            mb={{ base: "10px", xl: "50px" }}
            left="0"
            right="0"
            zIndex="999"
            paddingTop="10px"
            px={{ base: "20px", xl: "30px" }}
          >
            <Flex
              flex="1"
              border="1px solid black"
              borderRadius="10px"
              alignItems="center"
              justifyContent="center"
              bg="#D0F979"
            >
              <Image
                src={mode !== "learner" ? Learner : Helper}
                height="40px"
                width="100%"
                minWidth={{ base: "40px", xl: "80px" }}
              />
            </Flex>
            <Input
              width={{ base: "90%", xl: "85%" }}
              paddingLeft="15px"
              padding="10px"
              placeholder={
                keepChat ? t("StartTyping") : t("ChatOutOfLimitation")
              }
              onChange={(e) => setInput(e.target.value)}
              value={input}
              onKeyDown={handleEnter}
              disabled={!isAuthenticated || !keepChat}
              mx={2}
              _placeholder={{ color: "#86909c", fontSize: "14px" }}
              bg={"white"}
            />
            <Flex
              onClick={() => {
                if (input.trim() !== "") {
                  setChatBotMessages([
                    ...chatBotMessages,
                    { content: input, role: "user" },
                  ]);
                  handleSubmit(input);
                  setInput("");
                }
              }}
              borderRadius="10px"
              border="1px solid black"
              minW={{ base: "40px", xl: "auto" }}
              overflow={"hidden"}
            >
              <Image src={Send} height="40px" bg="#d2cffc" cursor="pointer" />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default TempChatBot;
