import axios from "axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { store } from "RTK/Store/store";
import { reset } from "RTK/Slice/userSlice";

let sharedAbortController = new AbortController();

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(reset());
      sharedAbortController.abort();
      localStorage.clear();
      sharedAbortController = new AbortController();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const request = async (method, url, body) => {
  const requestHeader = {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  };

  const requestOptions = {
    headers: requestHeader,
    signal: sharedAbortController.signal,
  };

  if (body) {
    // Handle methods like POST, PUT, DELETE with body
    return api[method](url, body, requestOptions);
  }

  // Handle methods like GET without body
  return api[method](url, requestOptions);
};

//文本流处理
let controller;
export const fetchTextStream = async (
  url,
  onChunkReceived,
  setter,
  doneCallBack,
  setOpenTokenModal,
  setOpenErrorModal,
  params = {},
  options = { method: "GET" }
) => {
  // 如果是 GET 请求，将参数附加到 URL 中
  if (options.method === "GET" && Object.keys(params).length > 0) {
    const queryParams = new URLSearchParams(params).toString();
    url += `?${queryParams}`;
  }
  // 如果是 POST 请求，将参数包含在 body 中
  if (options.method === "POST") {
    options.body = JSON.stringify(params);
  }
  url = `${process.env.REACT_APP_BASE_URL}/${url}`;
  controller = new AbortController(); // 创建一个新的控制器
  const signal = controller.signal;

  try {
    await fetchEventSource(url, {
      ...options,
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
        Accept: "text/event-stream",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      signal, // 传递信号以便可以中断请求
      onopen: () => console.log("Connection to SSE opened"),
      onmessage: (event) => {
        try {
          if (event.event === "error") {
            const data = JSON.parse(event.data);
            if (data.status_code === 405) {
              setOpenTokenModal(true);
            } else if (data.status_code === 500) {
              setOpenErrorModal(true);
            }
          } else {
            const data = JSON.parse(event.data);
            if (data.chat_again === undefined) {
              onChunkReceived(data.text);
            } else {
              setter(data.chat_again);
            }
          }
        } catch (error) {
          console.error("Error parsing SSE data:", error);
        }
      },
      onerror: (err) => {
        console.error("SSE error:", err);
        doneCallBack(false);
      },
      onclose: () => {
        console.log("SSE connection closed");
        doneCallBack(true);
      },
    });
  } catch (error) {
    console.error("Failed to fetch SSE:", error);
  }
};

export const fetchNewChatStream = async (
  url,
  handleChatId,
  handleAIResponse,
  doneCallBack,
  setInProgress,
  setOpenTokenModal,
  setOpenErrorModal,
  params,
  options = { method: "POST" }
) => {
  options.body = params;
  url = `${process.env.REACT_APP_BASE_URL}/${url}`;

  try {
    const response = await fetchEventSource(url, {
      ...options,
      headers: {
        ...options.headers,
        Accept: "text/event-stream",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      openWhenHidden: true,
      onopen: () => console.log("Connection to SSE opened"),
      onmessage: (event) => {
        try {
          if (event.event === "chat_id") {
            const data = JSON.parse(event.data);
            handleChatId(data);
          } else if (event.event === "error") {
            setInProgress(false);
            const data = JSON.parse(event.data);
            if (data.status_code === 405) {
              setOpenTokenModal(true);
            } else if (data.status_code === 500) {
              setOpenErrorModal(true);
            }
          } else if (event.event === "answer") {
            const data = JSON.parse(event.data);
            handleAIResponse(data.text);
          }
        } catch (error) {
          console.error("Error parsing SSE data:", error);
        }
      },
      onerror: (err) => {
        console.error("SSE error:", err);
        doneCallBack(false);
      },
      onclose: () => {
        console.log("SSE connection closed");
        doneCallBack(true);
      },
    });
  } catch (error) {
    console.error("Failed to fetch SSE:", error);
  }
};

export const abortFetchTextStream = () => {
  if (controller) {
    controller.abort(); // 中断请求
    console.log("FetchTextStream aborted");
  }
};

//格式化数据流访问的数据
const handleChunkReceived = (chunk) => {
  // 假设 chunk 的格式为 'data: {"text": "some text"}\ndata: {"text": "more text"}\n...'
  const lines = chunk.split("\n"); // 将 chunk 按行分割
  let combinedText = "";
  for (let line of lines) {
    if (line.startsWith("data: ")) {
      const data = JSON.parse(line.slice(6)); // 移除 'data: ' 并解析 JSON
      combinedText += data.text;
    }
  }
  return combinedText;
};
