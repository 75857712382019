import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import LinkExpired from '../../assets/LinkExpired.svg'
import { adminInvite, deleteUser } from "util/userRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "RTK/Slice/userSlice";

interface LinkExpiredModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    email: string;
}
const LinkExpiredModal: FC<LinkExpiredModalProps> = ({ open, setOpen, email }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const inviteUser = async () => {
        try {
            await adminInvite({
                email: email,
                redirect_to_url: `${window.location.origin}/setPassword/${email}`,
                invitation_token: "",
                platform_token: ""
            });
            navigate('/');
            dispatch(logoutUser())
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = async () => {
        const result = await deleteUser(email);
        if (result === 200 || result === 409) {
            inviteUser();
        }
    }
    return (
        <Modal
            open={open}
            footer={null}
            onCancel={() => {
                setOpen(false);
                navigate('/login');
            }}
            width={"600px"}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        marginBottom: "20px",
                    }}
                >
                    <span
                        style={{ marginLeft: "5px", fontSize: "large", fontWeight: "600" }}
                    >
                        {t("LinkExpired")}
                    </span>
                </div>
            </div>
            <div style={{ width: "100%" }}>
                <img src={LinkExpired} alt={"LinkExpired"} style={{ width: "100%" }} />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "60%",
                    margin: "0 auto",
                }}
            >
                <span
                    style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                        textAlign: "center",
                        fontWeight: "700",
                    }}
                >
                    {t("LinkExpiredContent")}
                </span>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "20px",
                    fontSize: "large",
                    fontWeight: "600",
                    cursor: "pointer",
                    border: "1px solid black",
                    borderRadius: '10px',
                    padding: '10px',
                    backgroundColor: '#D2CFFC'

                }}
                onClick={handleClick}
            >
                {t("ResendInvitation")}
            </div>
        </Modal>
    );
};

export default LinkExpiredModal;
