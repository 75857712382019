import { createSlice } from "@reduxjs/toolkit";

// Initial state template
const initialState = {
  checkSubModal: false,
  checkPremModal: false,
  invitModal: false,
  tokenModal: false,
  isClickSubscribe: false,
};

// Slice for modalState
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeCheckSubscribeModal: (state) => {
      state.checkSubModal = false;
    },
    openCheckSubscribeModal: (state) => {
      state.checkSubModal = true;
    },
    closeCheckPremiumModal: (state) => {
      state.checkPremModal = false;
    },
    openCheckPremiumModal: (state) => {
      state.checkPremModal = true;
    },
    closeInvitationModal: (state) => {
      state.invitModal = false;
    },
    openInvitationModal: (state) => {
      state.invitModal = true;
    },
    handleTokenModal: (state, action) => {
      state.tokenModal = action.payload;
    },
    setIsClickSubscribe: (state) =>{
      state.isClickSubscribe = true;
    },
    resetIsClickSubscribe: (state) =>{
      state.isClickSubscribe = false;
    }
    
  },
});

export const {
  closeCheckSubscribeModal,
  openCheckSubscribeModal,
  closeCheckPremiumModal,
  openCheckPremiumModal,
  closeInvitationModal,
  openInvitationModal,
  handleTokenModal,
  setIsClickSubscribe, 
  resetIsClickSubscribe,
  
} = modalSlice.actions;
export default modalSlice.reducer;
