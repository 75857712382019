import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { FC,useState } from "react";
import Sorry from "assets/Sorry.svg";

interface ErrorModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
  }

const ErrorModal:FC<ErrorModalProps> = ({ open, setOpen }) => {

    const { t } = useTranslation();
    const [displayMessage, setDisplayMessage] = useState(false);

    return (
        <div>
        <Modal
            open={open}
            footer={null}
            onCancel={() => {
                setOpen(false);
                setDisplayMessage(false);
            }}
            width={"600px"}
        >
        <div
            style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            }}
        >
            <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                marginBottom: "20px",
            }}
            >
            <span
                style={{ marginLeft: "5px", fontSize: "large", fontWeight: "600" }}
            >
                {t("Oops")}
            </span>
            </div>
        </div>
            <div style={{ width: "100%" }}>
            <img src={Sorry} alt={"Sorry"} style={{ width: "100%" }} />
            </div>
        <div
            style={{
            display: "flex",
            flexDirection: "column"
            }}
        >
            <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            >

            </div>
            <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            >
            <span style={{ fontSize: "large",fontWeight: "600", textAlign:"center" }}>
                    {t("ErrorNotification")}
                </span>
            </div>
        </div>
        </Modal>
        </div>
  );
};

export default ErrorModal;
