import { useNavigate, useParams } from "react-router-dom";
import { getSharedChat } from "util/userRequest";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChatId } from "RTK/Slice/userSlice";
import ChatItem from "Components/ChatItem/ChatItem";
import Logo from "../../assets/LOGO.svg";
import { useTranslation } from "react-i18next";
import Diamond from "../../assets/credit.svg";
import Coin from "../../assets/coin.svg";
import Yaoming from "../../assets/yaoming.svg";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";

const ShareSolution = () => {
  const { Id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sharedChat, setSharedChat] = useState(null);
  const { user } = useSelector((state) => state.user);
  const firstQuestionRef = useRef(null);
  const navigate = useNavigate();
  const getChat = async () => {
    const { data } = await getSharedChat(Id);
    let historyList = [{}];

    if (data["image_str"]) {
      historyList[0].imgSrc = data["image_str"];
    }

    if (data["payload"]["messages"].length < 2) {
      firstQuestionRef.current = data["question"];
    } else {
      const messages = data["payload"]["messages"].slice(2);

      messages?.forEach((item) => {
        if (item.role === "assistant" || item.role === "user") {
          historyList.push({
            content: item.content,
            role: item.role,
          });
        }
      });
    }

    setSharedChat([...historyList]);
  };
  const handleNavigate = () => {
    dispatch(setCurrentChatId(""));
    navigate("/");
  };

  useEffect(() => {
    if (Id) {
      getChat();
    }
  }, [Id]);

  const getUsernameLetter = () => {
    const username = user?.email || "U";
    return username.charAt(0).toUpperCase();
  };

  const [isMobile] = useMediaQuery('(max-width: 1280px)')

  console.log(isMobile);
  return (
    <>
      {isMobile ? (
        <Box
          bg="#feeede"
          height="100%"
          width="100%"
          borderRadius={{base:'0',xl:'20px'}}
          overflow="auto"
          position="relative"
        >
          <Flex
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "10px",
              }}
            >
              <img
                src={Logo}
                style={{
                  height: "100%",
                  width: "100%",
                  transform: "scale(0.6)",
                }}
              />
              <span style={{ fontWeight: "700", lineHeight: "20px" }}>
                {t("ShareH1")}
              </span>

              <span
                style={{
                  fontWeight: "400",
                  lineHeight: "15px",
                  fontSize: "12px",
                }}
              >
                {t("ShareSubH1")}
              </span>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    flex: "1",
                    alignItems: "center",
                  }}
                >
                  <img src={Diamond} />
                  <span
                    style={{
                      fontWeight: "700",
                      lineHeight: "15px",
                      fontSize: "11px",
                    }}
                  >
                    {t("ShareSubH4")}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    flex: "1",
                    alignItems: "center",
                  }}
                >
                  <img src={Coin} />
                  <span
                    style={{
                      fontWeight: "700",
                      lineHeight: "15px",
                      fontSize: "11px",
                    }}
                  >
                    {t("ShareSubH5")}
                  </span>
                </div>
              </div>
            </div>
          </Flex>
          <div style={{ marginBottom: "80px" }}>
            {sharedChat?.map((element, index) => {
              return (
                <ChatItem
                  key={index}
                  role={element.role}
                  message={element.content}
                  imgSrc={element.imgSrc}
                  username={getUsernameLetter()}
                  lastMessage={index === sharedChat.length - 1}
                  typingSpeed={0}
                  scrollRef={null}
                  defaultRole={"assistant"}
                />
              );
            })}
          </div>
          <Flex
            hideFrom={'xl'}
            style={{
              backgroundColor: "white",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              height: "80px",
              bottom: "0",
              width: "100%",
              opacity: "0.5",
            }}
          ></Flex>
          <Flex
            style={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              height: "80px",
              backgroundColor: "transparent",
              alignItems: "center",
            }}
            hideFrom={'xl'}
          >
            <div
              style={{
                backgroundColor: "#D0F979",
                width: "70%",
                height: "60%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "auto",
                border: "1px solid black",
                gap: "10px",
                borderRadius: "10px",
              }}
              onClick={handleNavigate}
            >
              <img
                src={Yaoming}
                style={{ height: "80%", marginLeft: "10px" }}
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "20px",
                }}
              >
                {t("ShareH2")}
              </span>
            </div>
          </Flex>
        </Box>
      ) : (
        <div
          style={{
            backgroundColor: "#feeede",
            height: "100%",
            width: "100%",
            borderRadius: "20px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "90%",
              margin: "0 auto",
              overflow: "hidden",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                overflow: "auto",
                flex: "1",
                position: "relative",
              }}
            >
              {sharedChat?.map((element, index) => {
                return (
                  <ChatItem
                    key={index}
                    role={element.role}
                    message={element.content}
                    imgSrc={element.imgSrc}
                    username={getUsernameLetter()}
                    lastMessage={index === sharedChat.length - 1}
                    typingSpeed={0}
                    scrollRef={null}
                    defaultRole={"assistant"}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareSolution;
