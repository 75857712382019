import PasswordModal from "./PasswordModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Image,
  Text,
  VStack,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import SaltBaeIcon from "assets/salt-bae.svg";
import { EmailIcon } from "@chakra-ui/icons";
import { resetPassword } from "util/userRequest";

const ForgetPassword = () => {
  const [recoverEmail, setRecoverEmail] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleConfirm = async () => {
    try {
      const { error } = await resetPassword({
        email: recoverEmail,
        options: {
          redirect_to: `${window.location.origin}/resetPassword`,
        },
      });
      if (error) {
        throw error;
      }
      setRecoverEmail("");
      navigate("/checkEmail");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box h={"100%"} w={"100%"}>
      <PasswordModal>
        <VStack spacing={4} align="stretch" width={{ base: "90%", xl: "70%" }}>
          <Box>
            <Heading fontWeight={400} fontSize={"14px"}>
              {t("ForgetPasswords")}
            </Heading>
          </Box>
          <Box>
            <Text fontSize={"18px"} fontWeight={500}>
              {t("ForgetPasswordsMessage")}
            </Text>
          </Box>
          <InputGroup border={"1px solid black"} borderRadius={"5px"}>
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="black" />
            </InputLeftElement>
            <Input
              background={"#d0f979"}
              type="email"
              value={recoverEmail}
              onChange={(e) => setRecoverEmail(e.target.value)}
              placeholder="Email"
              border="none"
              _focus={{ outline: "none" }}
              h={"50px"}
            />
          </InputGroup>

          <Flex justifyContent="center" mt={4}>
            <Image src={SaltBaeIcon} />
          </Flex>
          <Flex justifyContent="space-between" mt={4} flexDirection={"column"}>
            <Button
              onClick={handleConfirm}
              bgColor={"#d2cffc"}
              h={"60px"}
              border={"1px solid #000"}
            >
              {t("Confirm")}
            </Button>
            <Button
              border={"1px solid #000"}
              bg={"transparent"}
              mt={"10px"}
              h={"60px"}
              onClick={() => {
                setRecoverEmail("");
                navigate("/login");
              }}
            >
              {t("Cancel")}
            </Button>
          </Flex>
        </VStack>
      </PasswordModal>
    </Box>
  );
};

export default ForgetPassword;
